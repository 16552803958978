import { BOOL } from "../../util/constants";
import { dropdownSubtypes, inputMappings } from "../../util/enums";

export const formSections = [
  {
    title: "Faculty Member Information",
    id: "faculty-member-info",
    validation: (values) => {
      const facultyMember = (values?.["faculty-lookup"]?.value ?? {});
      const keys = Object.keys(facultyMember).filter(key => (key !== "emplid"));
      return (keys.length > 0 && facultyMember?.facultyNomination === true); // has a key other than emplid
    },
    questions: [
      {
        id: "faculty-lookup",
        type: "lookup",
        dataField: "facultyMember",
        required: true,
        facultyNomination: true,
        title: "Faculty Lookup",
        graphql: [
          {
            label: 'OSUID',
            dataField: 'emplid'
          },
          {
            label: 'First Name',
            dataField: 'firstName'
          },
          {
            label: 'Middle Name',
            dataField: 'middleName'
          },
          {
            label: 'Last Name',
            dataField: 'lastName'
          },
          {
            label: 'Faculty Phone',
            dataField: 'phone'
          },
          {
            label: 'Faculty E-mail',
            dataField: 'facultyEmail'
          },
          {
            label: 'Faculty Nomination',
            dataField: 'facultyNomination',
            hidden: true
          }
        ]
      },
    ],
  },
  {
    title: "Academic Information",
    id: "acad-info",
    dependsOn: "faculty-member-info",
    questions: [
      {
        id: "faculty-rank",
        title: "Faculty Rank",
        required: true,
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.facultyRank,
        dataField: "facultyRank"
      },
      {
        id: "academic-unit",
        title: "Academic Unit",
        summary: "Faculty Member's Home Department",
        required: true,
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.academicUnit,
        dataField: "academicPlan"
      },
      {
        id: "highest-degree",
        title: "Highest Degree Obtained",
        required: true,
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.degrees,
        dataField: "highestDegree"
      },
      {
        id: "osu-degree",
        title: "Was this degree obtained at the Ohio State University?",
        required: true,
        type: inputMappings.radio,
        dataField: "osuDegree",
        choices: [
          {
            id: "yes",
            value: BOOL.YES,
            title: "Yes",
            branch: "inside-degree"
          },
          {
            id: "no",
            value: BOOL.NO,
            title: "No",
            branch: "outside-degree"
          },
        ]
      },
      {
        id: "degree-year",
        type: inputMappings.textInput,
        required: true,
        title: "Degree Year",
        dataField: "degreeYear"
      },
      {
        id: "outside-degree",
        type: inputMappings.inputGroup,
        numberOfResponsesMaximum: 1,
        dependencies: ["osu-degree"],
        questions: [
          {
            id: "outside-degree-institution",
            type: inputMappings.textInput,
            required: true,
            title: "Degree Institution",
            dataField: "degreeInstitution"
          }
        ]
      },
      { 
        id: "inside-degree",
        type: inputMappings.inputGroup,
        numberOfResponsesMaximum: 1,
        dependencies: ["osu-degree"],
        questions: [
          {
            id: "inside-degree-institution",
            type: inputMappings.textInput,
            required: true,
            title: "Degree Institution",
            dataField: "degreeInstitution",
            value: "OSU",
            hidden: false
          }
        ]
      }
    ],
  },
  {
    title: "Proposed Category",
    id: "proposed-category",
    dependsOn: "faculty-member-info",
    questions: [
      {
        id: "category",
        title: "Category",
        dataField: "proposedCategory",
        required: true,
        type: inputMappings.radio,
        choices: [
          {
            id: "P",
            value: "P",
            title: "P",
            branch: "file-uploder"
          },
          {
            id: "M",
            value: "M",
            title: "M",
          },
        ],
      },
      {
        id: "grad-plan-admin-programs",
        title: "Grad Plan Admin Programs",
        dataField: "proposedGradProgram",
        required: false,
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.gradPlanAdminPrograms,
        hidden: false
      }
    ]
  },
  {
    title: "Attachments",
    id: "attachments",
    dependsOn: "faculty-member-info",
    questions: [
      {
        id: "file-uploder",
        type: "fileUpload",
        title: "Document Upload: If this nomination is for Category P status please attach a letter of request from the Graduate Studies Committee and a current C.V. for the nominee.",
        summary: "If this nomination is for Category P status please attach a letter of request from the Graduate Studies Committee and a current C.V. for the nominee. ",
        required: false,
        multiple: true,
        dataField: "attachments",
        defaultValue: null
      },
      {
        id: "additional-comments",
        dataField: "comments",
        type: "textInput",
        title: "Additional Comments",
        multiline: true,
        rows: 5,
        summary:
          "Information in this request is viewable and subject to disclosure under the Ohio public records statute and rules discovery.",
        required: false,
      },
    ]
  }
];