import { Mui, usePrevious } from "@osu/react-ui";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { links } from "../paths";
import StandardTemplate from "../templates/Standard";
import { ACTION_STATUS } from "../../util/constants";

function Mapper(params) {
  const {
    formSections,
    initialState,
    onSubmit,
    onSave,
    errorState,
    getSections,
    formType,
    formTypeFriendly,
    formId,
    loadGetFormCall,
    loading,
    submissionId,
    submissionOSUID,
    submissionStatus,
    submissionErrorMessage,
    navigate,
    navigationMessage,
    location,
    match,
    resetDropdowns,
    resetFormError,
    resetSubmission,
    user,
    status
  } = params;
  const osuid = match?.params?.osuid;
  const pathname = (location?.pathname ?? "");
  const previousPathname = usePrevious(pathname);
  const alertRef = useRef(null);
  const [alert, setAlert] = useState(null);
  const previousAlert = usePrevious(alert);
  const [isSubmitting, setIsSubmitting] = useState(null);

  const onAlertClose = (
    (alert && alert?.close === true) ? 
      () => {
        setAlert(null);
        if(alert.onClose) alert.onClose();
      } :
      null
  );

  const handleSave = (formData, stepState) => {
    if(errorState) resetFormError()
    setIsSubmitting(false);
    onSave({ id: formId, formType, formData, osuid, stepState, user });
    setAlert({ message: "Saving the form..." });
  };

  const handleSubmit = (formData, validity, stepState) => {
    if(errorState) resetFormError()
    setIsSubmitting(true);
    onSubmit({ id: formId, formType, formData, osuid, stepState, user });
    setAlert({ message: "Submitting the form..." });
  };

  // when there is an existing form, get the form data
  useEffect(() => {
    if(loadGetFormCall && formId) getSections({ id: formId, osuid, formType });
  }, [getSections, formId, formType, loadGetFormCall, osuid]);

  // when there is a navigation message, navigate to the landing page to show the error
  useEffect(() => {
    if(navigationMessage) navigate(`/form/${formTypeFriendly}`, { state: { errorMessage: navigationMessage } });
  }, [navigationMessage, formTypeFriendly, navigate]);

  // when the alert changes, set focus
  useEffect(() => {
    if(!previousAlert && alert) { // alert is shown
      if(alertRef?.current) alertRef.current.focus(); // set focus on alert
    }
    if(previousAlert && !alert) { // alert is removed
      // set focus on header (so that focus is not lost)
      const h1 = document.getElementsByTagName("h1")?.[0];
      if(h1) h1.focus();
    }
  }, [alert, previousAlert]);

  // when the status changes, show an alert on error or navigate on success
  useEffect(() => {
    if(errorState) {
      setAlert({
        message: "The form has validation errors that must be resolved before the form can be submitted.",
        severity: "error",
        close: true,
        onClose: () => (resetFormError())
      });
    } else {
      if(submissionStatus === ACTION_STATUS.ERROR) {
        const message = (submissionErrorMessage ?? `An error occurred while ${(isSubmitting ? "submitting" : "saving")} the form.`);
        setAlert({ message, severity: "error", close: true });
      }
      if(submissionStatus === ACTION_STATUS.SUCCESS) {
        const newPath = (isSubmitting ? "view" : "edit");

        let newLocation;
        if(pathname.endsWith("create")) {
          newLocation = pathname.replace("create", newPath);
          newLocation += `/${submissionId}`;
          if(submissionOSUID) newLocation += `/${submissionOSUID}`;
        }
        if(pathname?.includes("/edit/")) newLocation = pathname.replace("/edit/", `/${newPath}/`);
        if(newLocation !== pathname) {
          navigate(newLocation, { replace: true });
        } else {
          resetSubmission(); // avoid additional triggers of this effect
        }

        if(isSubmitting) {
          setAlert(null);
        } else {
          setAlert({
            message: "Your application has been saved but not submitted. Please review the application and submit when you are ready.",
            close: true
          });
        }
      }
    }
    
  }, [errorState, isSubmitting, location, navigate, pathname, resetFormError, resetSubmission, submissionErrorMessage, submissionId, submissionOSUID, submissionStatus]);

  // when navigation succeeds, reset the submission to avoid additional effects from triggering
  useEffect(() => {
    if(previousPathname && pathname !== previousPathname) resetSubmission();
  }, [pathname, previousPathname, resetSubmission])

  // on unmount, reset fields and data
  useEffect(() => {
    return () => {
      resetDropdowns();
      resetFormError();
      resetSubmission();
    };
    // eslint-disable-next-line
  }, []); // array is purposefully empty to force clean-up only on unmount

  return (formType ?
    (
      <Fragment>
        {alert?.message && 
          (<Mui.Alert ref={alertRef} tabIndex="-1" className="margin-bottom-2" severity={alert?.severity ?? "info"}
            sx={{ whiteSpace: "pre-line" }} onClose={onAlertClose}>
              {alert?.title && (<Mui.AlertTitle>{alert.title}</Mui.AlertTitle>)}
              {alert.message}
          </Mui.Alert>)
        }
        <StandardTemplate
          formSections={formSections}
          onSubmit={handleSubmit}
          onSave={handleSave}
          initialState={initialState}
          errorState={errorState}
          loading={loading}
        />
      </Fragment>
    ) :
    (
      <Mui.List>
        {links.map((link) => (
          <Mui.ListItem>
            <Mui.Link component={Link} to={link.path}>
              {link.title}
            </Mui.Link>
          </Mui.ListItem>
        ))}
      </Mui.List>
    )
  );
}

export default Mapper;