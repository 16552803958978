import { Mui } from "@osu/react-ui";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { pick } from "lodash";
import { constructPersonString } from "../../transform";
import useLookup from "../../hooks/useLookup";
import { personInfo } from "../../../util/constants";

// Note: This component currently uses emplid when performing a person lookup.
// TODO: Update component to use nameN for person lookup once that operation is available

function NameNLookup(props = {}) {
  const { value: val, onChange, student, className } = props;
  const lookupButton = useRef();
  const [emplid, setEmplid] = useState("");
  const [lookupClicked, setLookUpClicked] = useState(false);
  const { person = {}, getPerson, setPersonEmplid, status } = useLookup({ student });
  const loading = status === "loading";
  const value = Object.prototype.hasOwnProperty.call(val || {}, 'emplid') ? val.emplid : val
  const updateEmpl = (value) && value !== emplid
  const triggerChange = lookupClicked && person?.emplid
  const error = (['notfound', 'error'].includes(status));

  useEffect(() => {
    if(updateEmpl) {
      setEmplid(value);
      setPersonEmplid(value);
    }
  }, [setPersonEmplid, updateEmpl, value]);

  useEffect(() => {
    if(triggerChange) {
      onChange(pick(person, Object.keys(personInfo.write)));
      setLookUpClicked(false)
    }
  }, [onChange, person, triggerChange]);

  return (
    <div className={className}>
      {person?.emplid ?
        (<Mui.Box className="margin-left-2" display="flex" flexDirection="row">{constructPersonString(person)}</Mui.Box>) :
        (<Fragment>
          <Mui.Button
            id="textField1"
            className="text-transform-none"
            marginleft={5}
            value={value}
            disabled={loading}
            onChange={(e) => {
              const value = e.target.value;
              onChange({ emplid: value });
              setEmplid(value);
            }}>
          <Mui.TextField variant="outlined" label="OSU ID" /*label="Name.#"*/ value={value} error={error === true}
            helperText={error === true ? 
              "The OSU ID entered did not return any results. Please re-check your OSU ID and try again." /*"The Name.# entered did not return any results. Please re-check the Name.# and try again."*/ :
              ""
            }
            inputProps={{
              onKeyDown: (e) => {
                if(e.key === "Enter") {
                  e.preventDefault(); // prevent form submission
                  // click the lookup button when enabled
                  if(lookupButton?.current && lookupButton.current?.disabled === false) {
                    lookupButton.current.click();
                  }
                }
              }
            }} />
          </Mui.Button>
          <Mui.Button
            ref={lookupButton}
            color="primary"
            variant="contained"
            disabled={loading || !emplid}
            onClick={() => {
              setLookUpClicked(true)
              getPerson(emplid);
            }}
            endIcon={loading && <Mui.CircularProgress color="inherit" size={20} />}
            >
              Lookup
          </Mui.Button>
        </Fragment>)
      }
    </div>
  );
}

export default NameNLookup;