import { Mui, usePrevious } from "@osu/react-ui";
import React, { Fragment, useEffect, useState } from "react";

function TextInput(props = {}) {
  const { id, value, className, required, onChange, title, defaultValue, type, error: formError, skType, dataField, gridProps, filterState, summary, getStudentInfo, ...rest } = props;
  const summaryProps = Object.assign({ className: null, component: "div", top: false, variant: "body2" }, (rest.summaryProps ?? {}));

  const [shrink, setShrink] = useState(!!value)
  const previousDefaultValue = usePrevious(defaultValue)
  const [error, setError] = useState(formError)

  const onBlur = () => {
    if(type === "tel" && value) {
      const phoneLength = value.match(/(\d)/g).length
      setError(phoneLength === 10 
        ? ""
        : "Please enter a valid 10 digit phone number."
        )
    }
    setShrink(!!value)
  }

  useEffect(() => {
    if(onChange && !previousDefaultValue && !value && defaultValue) {
      onChange({
        id,
        value: defaultValue
      });
    }
  }, [defaultValue, id, onChange, previousDefaultValue, value])

  useEffect(() => {
    setError(err => {
      if(err !== formError) {
          return formError
       }
      return err
    });
  }, [formError]);

  const summaryClassName = (summaryProps.top === true ? `margin-bottom-1 ${summaryProps.className}` : `margin-top-1 ${summaryProps.className}`);
  const Summary = (<Mui.Typography className={summaryClassName} component={summaryProps.component} variant={summaryProps.variant}>{summary}</Mui.Typography>);

  return <Mui.FormControl className={className} fullWidth={true}>
    {(summary && summaryProps.top === true) && Summary}
    <Mui.TextField
      id={id}
      value={value ?? ""}
      required={required}
      error={!!error}
      onFocus={() => {
        setShrink(true)
      }}
      onBlur={onBlur}
      onChange={(e) => {

        onChange({
          id,
          value: e.target.value,
        });
      }}
      label={title}
      type={type}
      InputLabelProps={{ shrink: !!value || shrink }}
      {...rest}
      helperText={error}
    />
    {(summary && summaryProps.top === false) && Summary}
  </Mui.FormControl>;
}

TextInput.defaultProps = {
  variant: "outlined",
  value: ""
};

export default TextInput;
