import { BOOL } from "../../util/constants";
import { dropdownSubtypes, inputMappings } from "../../util/enums";

export const formSections = [
  {
    id: "form",
    questions: [
      {
        title: "Graduation Level",
        id: "graduation-information-level",
        required: true,
        type: inputMappings.dropDown,
        summary: "If you are uncertain of your academic level, please contact your graduate program coordinator.",
        subtype: dropdownSubtypes.graduationLevel,
        branch: "graduation-information-plan",
        dataField: "gradLevel"
      },
      {
        title: "Graduation Plan",
        id: "graduation-information-plan",
        required: true,
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.graduatePrograms,
        filter: [
          {
            field: "graduation-information-level",
            match: "gradLevel",
            operation: "includes"
          }
        ],
        dataField: "academicPlan",
        results: [
          {
            label: "Graduation Degree",
            field: "degree",
            dataField: "gradDegree"
          }
        ],
        dependencyHelperText: "Please select a level to select the appropriate plan.",
        dependencies: ["graduation-information-level"]
      },
      {
        title: "Credit Type",
        id: "credit-type",
        dataField: "creditType",
        type: inputMappings.dropDown,
        summary: "Select Graduate Non-Degree if requesting OSU non-degree credit (up to 10 credit hours) be applied to your current program. Select Outside Institution if requesting external (non-OSU) credits be applied to your current program. Please refer to the Graduate School Handbook for limitations.",
        subtype: dropdownSubtypes.creditType,
        required: true
      },
      {
        id: "transfer-courses",
        title: "Transfer Courses",
        type: inputMappings.inputGroup,
        numberOfResponsesMinimum: 1,
        numberOfResponsesMaximum: 10,
        dataField: "courses",
        required: true,
        questions: [
          {
            id: "courses-ig-institution",
            type: inputMappings.textInput,
            title: "Institution",
            dataField: "institution",
            summary: null,
            required: true,
            gridProps: {
              xs: 6,
              lg: 4,
              md: 6
            }
          },
          {
            id: "courses-ig-title",
            type: inputMappings.textInput,
            title: "Course Title",
            dataField: "courseTitle",
            summary: null,
            required: true,
            gridProps: {
              xs: 6,
              md: 6,
              lg: 4
            }
          },
          {
            id: "courses-ig-number",
            type: inputMappings.numberInput,
            title: "Course Number",
            dataField: "courseNumber",
            summary: null,
            required: true,
            gridProps: {
              xs: 6,
              md: 4,
              lg: 2
            },
            inputProps: {
              min: 0
            }
          },
          {
            id: "courses-ig-credit-hours",
            type: inputMappings.numberInput,
            title: "Credit Hours",
            dataField: "creditHours",
            summary: null,
            required: true,
            gridProps: {
              xs: 6,
              md: 4,
              lg: 2
            },
            inputProps: {
              min: 0
            }
          }
        ]
      },
      {
        id: "advisor",
        type: inputMappings.dropDown,
        title: "Advisor",
        subtype: dropdownSubtypes.advisor,
        summary: null,
        required: false,
        numberOfResponsesMinimum: 1,
        numberOfResponsesMaximum: 1,
        dataField: "advisor",
        dependencies: ["graduation-information-plan"],
        dependencyHelperText: "Please select a plan to select the appropriate advisor.",
        choices: []
      },
      {
        id: "credit-block",
        type: inputMappings.checkbox,
        dataField: "creditBlock",
        defaultValue: BOOL.NO,
        hidden: true,
        choices: [
          {
            id: "credit-block-yes",
            title: "Student's Master's degree will count as block of 30 hours credit",
            value: BOOL.YES
          }
        ]
      },
      {
        id: "digital-transcripts",
        type: inputMappings.checkbox,
        dataField: "digitalTranscripts",
        defaultValue: BOOL.NO,
        hidden: true,
        choices: [
          {
            id: "digital-transcripts-yes",
            value: BOOL.YES,
            title: "Digital transcripts from outside institution(s) submitted to Graduate Admissions"
          }
        ]
      }
    ]
  }
];

export const gradChairApprovalQuestions = [
  {
    id: "credit-block",
    type: inputMappings.checkbox,
    dataField: "creditBlock",
    className: "margin-bottom-0",
    choices: [
      {
        id: "credit-block-yes",
        value: BOOL.YES,
        title: "Student's Master's degree will count as block of 30 hours credit"
      }
    ]
  },
  {
    id: "digital-transcripts",
    type: inputMappings.checkbox,
    dataField: "digitalTranscripts",
    choices: [
      {
        id: "digital-transcripts-yes",
        value: BOOL.YES,
        title: "Digital transcripts from outside institution(s) submitted to Graduate Admissions"
      }
    ]
  }
];

export const additionalFilters = [
  {
    id: "credit-type",
    title: "Credit Type",
    required: false,
    type: inputMappings.dropDown,
    subtype: dropdownSubtypes.creditType
  }
];