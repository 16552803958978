import { Mui } from "@osu/react-ui";
import React, { Fragment, useEffect } from "react";
import { invert } from "lodash";
import { FORM_TYPE_HTML_FRIENDLY } from "../../util/constants";

const LINE_WRAP_THRESHOLD = 3
const GROUP_WRAP_THRESHOLD = 5

const useStyles = () => ({
  field: {
    minWidth: "10rem",
    maxWidth: "50%",
    display: "inline-block"
  }
})


function Responses(params) {
  const { fetchData, formId, title, getSections, osuid, responses, loading, match = {}, setHeading, error, setErrorMessage } = params;
  const formType = params.formType ?? invert(FORM_TYPE_HTML_FRIENDLY)[match?.params?.formType];

  useEffect(() => {
    setHeading('Form Responses')
  }, [setHeading])

  useEffect(() => {
    fetchData && getSections({ id: formId, osuid, formType });
  }, [fetchData, formId, getSections, osuid, formType]);

  useEffect(() => {
    setErrorMessage(existing => {
      if(!error && existing) {
        return ""
      } else if (error) {
        return error
      }
    })
  }, [error, setErrorMessage])

  let results = [];
  let idx = 0;

  if (responses?.length) {
    responses.forEach((currentResponse = {}) => {
      const { id: sectionId, title, wrap, titleProps = {}, responses: resps = [] } = currentResponse
       let key = `${sectionId}-response-${idx}`;
      idx++;
      let nestedResponses = []
      let standardResponses = []
      Array.isArray(resps) && resps.forEach((resp = {}, idx) => {
        const { hidden = false } = resp;
        const arrToRender = Array.isArray(resp?.responses) 
          ? resp.responses
          : Array.isArray(resp.value)
          ? resp.value
          : Array.isArray(resp)
          ? resp
          : []
        if(arrToRender.length) {
          nestedResponses.push(
            <SplitUI
              key={nestedResponses.length}
              title={resp?.title || resp?.label}
              component={Mui.Paper}
              className={`padding-2 margin-1${hidden === true ? " display-none" : ""}`}
              variant="outlined"
              wrap={resp.length > GROUP_WRAP_THRESHOLD}
              responses={arrToRender.map((prps, i) => {
                let label, value
                if(Array.isArray(prps)) {
                  value = prps
                } else {
                  label = prps?.label
                  value = prps?.value
                }
                return (
                  <Response
                    key={`${sectionId}-${idx}-response-${i}`}
                    label={label ?? resp?.title}
                    value={value}
                    lastItem={arrToRender.length === (i + 1)}
                  />
                );
              })}
            />
          );
        } else {
          standardResponses.push(<Response key={`${sectionId}-${idx}-response`} {...resp} />)
        }
      });
      
      let el = (
        <Fragment key={key}>
          {!!title && (
            <Mui.Typography
              className="margin-top-2"
              variant="h4"
              component="h3"
              {...titleProps}
            >
              {title}
            </Mui.Typography>
          )}
          {!!standardResponses?.length && 
            <SplitUI wrap={wrap === undefined ? standardResponses.length > LINE_WRAP_THRESHOLD : wrap} responses={standardResponses} />
          }
          {!!nestedResponses?.length && 
            <SplitUI wrap={wrap === undefined ? nestedResponses.length > LINE_WRAP_THRESHOLD : wrap} responses={nestedResponses} />
          }
        </Fragment>
      );
      results.push(el);
    });
  }
  if (loading) {
    const FauxResult = ({ width1 = "15%", width2 = "20%" }) => (
      <Mui.Box display="flex">
        <Mui.Skeleton
          width={width1}
          height="2rem"
          className="margin-right-2"
        />
        <Mui.Skeleton width={width2} height="2rem" />
      </Mui.Box>
    );
    results = (
      <Fragment>
        <Mui.Skeleton height="4rem" width="30%" />
        <SplitUI responses={[<FauxResult key="skel-0-0" />]} />
        <Mui.Skeleton className="margin-top-2" height="4rem" width="25%" />
        <SplitUI
          responses={[
            <FauxResult key="skel-1-0" width1="30%" width2="45%" />,
            <FauxResult key="skel-1-1" width1="20%" width2="55%" />,
            <FauxResult key="skel-1-2" width1="40%" width2="50%" />,
            <FauxResult key="skel-1-3" width1="20%" width2="45%" />,
          ]}
        />
        <Mui.Skeleton className="margin-top-2" height="4rem" width="35%" />
        <SplitUI responses={[<FauxResult key="skel-2-0" />, <FauxResult key="skel-2-1" />, <FauxResult key="skel-2-2"  />]} />
      </Fragment>
    );
  }
  return results
}

const Title = ({ label, ...rest }) => {
  const specialRegex = /[^A-Z a-z0-9]/ 
  const endsInSpecialChar = label && specialRegex.test(label) 

  if(!label) {
    return null
  }
  return <Mui.Typography component="span" variant="body1" {...rest}>
  {label}{!endsInSpecialChar && ":"}
</Mui.Typography>
}

const Response = (props = {}) => {
  const { label, value, bold = true, valueProps, titleProps, wrap: wrapResponses, lastItem, ...rest } = props;
  const classes = useStyles()

  const View = ({ l, v, className }) => {
    let cls = `padding-left-2 ${bold ? 'bol ' : ""}${classes.field}`
    if(className) {
      cls+= " " + className
    }
      return <Mui.Typography {...rest}>
      <Title color="secondary" variant="body2" className={cls} label={l} {...titleProps || {}} />
      <Mui.Typography component="span" variant="body1" {...valueProps || {}}>
        {" "}
        {v ?? "-"}
      </Mui.Typography>
    </Mui.Typography>
  }

  if(Array.isArray(value)) {
    const wrap = wrapResponses === undefined ? value.length > GROUP_WRAP_THRESHOLD : wrapResponses
    const innerViews = value.map((nestedItem, nestedIdk) =>{
      return   <View
      key={`${encodeURIComponent(label)}-${encodeURIComponent(value)}-nested-${nestedIdk}`}
      l={nestedItem?.label}
      bold={false}
      v={nestedItem?.value}
    />  
    })

    return <Mui.Box>
      <Title className={`bold ${classes.field}`} label={label}/>
      {wrap ? <Fragment>
        <WrappedUI responses={innerViews} />
        {!lastItem && <Mui.Divider className="margin-y-1" />}
      </Fragment> : innerViews}
    </Mui.Box>
  }
  return (
    <View  
      l={label}
      v={value}
    />
  );
};

const WrappedUI = ({ responses, className, ...rest }) => {
  const smallDevice = Mui.useMediaQuery(theme => theme.breakpoints.down('sm'))
  const half = Math.ceil(responses.length / 2);
  const firstHalf = responses.slice(0, half);
  const secondHalf = responses.slice(half);
  
  if(smallDevice) {
    return responses
  }
  let cls = className ?? ""
  cls += " display-flex"
    return  <Mui.Box  { ...rest} className={cls}>
    <Mui.Box flex="50%">{firstHalf}</Mui.Box>
    <Mui.Box flex="50%">{secondHalf}</Mui.Box>
  </Mui.Box>
}

const SplitUI = ({ responses, wrap: wrapResponses, title, ...rest }) => {
  let display = responses;
  const wrap = wrapResponses === undefined ? 
    Array.isArray(responses?.[0]) &&
    responses.length > LINE_WRAP_THRESHOLD 
  : wrapResponses

  if (wrap) {
    display = (
      <Fragment>
          {title && <Title variant="h6" component="h3" label={title} />}
        <WrappedUI responses={responses}></WrappedUI>
      </Fragment>
    );
  } else if(rest.component) {
    display = <Mui.Box { ...rest }>
          {title && <Title variant="h6" component="h3" label={title} />}
      {responses}
    </Mui.Box>
  }

  return display;
};

export {
  Responses as default,
  WrappedUI,
  Response
}
