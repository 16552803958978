import { BOOL } from "../../util/constants";
import { dropdownSubtypes, inputMappings } from "../../util/enums";

export const formSections = [
  {
    title: "Graduation Information",
    id: "graduation-information",
    questions: [
      {
        title: "Graduation Level",
        id: "graduation-information-level",
        required: true,
        type: "dropDown",
        dataField: "gradLevel",
        branch: "graduation-information-plan",
        subtype: dropdownSubtypes.finalDocumentLevels
        //Filters plan based on a specific level
        //Ideal state -> remove this and automatically filter plans
      },
      {
        title: "Graduation Plan",
        id: "graduation-information-plan",
        required: true,
        type: "dropDown",
        dataField: "academicPlan",
        subtype: dropdownSubtypes.graduatePrograms,
        filter: [
          {
            field: "graduation-information-level",
            match: "gradLevel",
            operation: "includes"
          }
        ],
        branch: "advisor-info",
        results: [
          {
            label: "Graduation Degree",
            field: "degree",
            dataField: "gradDegree"
          }
        ],
        dependencyHelperText: "Please select a level to select the appropriate plan.",
        dependencies: ["graduation-information-level"]
      }
    ]
  },
  {
    id: "advisor",
    title: "Advisor Information",
    questions: [
      {
        id: "advisor-info",
        type: "dropDown",
        title: "Advisor",
        dataField: "advisor",
        subtype: dropdownSubtypes.advisor,
        dependencies: ["graduation-information-plan"],
        dependencyHelperText: "Please select a plan to select the appropriate advisor.",
        required: true
      }
    ]
  },
  {
    id: "delay-request-info",
    title: "Delay Request Information",
    questions: [
      {
        id: "ohio-link",
        type: inputMappings.textInput, // string (not number)
        title: "OhioLINK Number",
        dataField: "ohioLink",
        required: true
      },
      {
        id: "reason-for-delaying-document",
        type: inputMappings.textInput,
        title: "Reason for Delaying Document",
        summary: "In the space below, briefly outline your reasons for requesting a delay.",
        summaryProps: {
          top: true
        },
        dataField: "reason",
        multiline: true,
        required: true,
        rows: 5,
        inputProps: {
          maxLength: 250,
          style: {
            resize: "vertical"
          }
        }
      },
      {
        id: "delay-period-years",
        type: inputMappings.dropDown,
        title: "Delay Period Desired (in years)",
        dataField: "delayPeriod",
        choices: [...Array(5)].map((empty, index) => ({
          id: `delay-period-${index}`,
          title: index + 1,
          value: index + 1
        })),
        required: true
      }
    ]
  },
  {
    id: "disclosure-confirmation",
    title: "Disclosure Confirmation",
    questions: [
      {
        id: "ohio-link-understanding",
        type: "checkbox",
        defaultValue: BOOL.NO,
        summary: "If an extension of the OhioLINK delay is necessary, you must request one from the Graduate School before the delay expires. If you do not request an extension, your dissertation will be released according to the original schedule. All Ohio State dissertations are microfilmed by and archived with ProQuest/UMI. Ohio State Master’s theses are not processed with ProQuest/UMI.",
        required: true,
        dataField: "disclosureOhioLink",
        choices: [
          {
            id: "ohio-link-understanding-confirmation",
            title: "I understand the following:",
            responseTitle: "Student understands that any extensions of the OhioLINK delay must be requested before the delay expires.",
            value: BOOL.YES
          }
        ]
      },
      {
        id: "pro-request-restrict-understanding",
        type: "checkbox",
        summary: "If you want to restrict access to your dissertation via ProQuest/UMI and its third-party vendors (such as Amazon.com and Google), it is your responsibility to contact ProQuest/UMI directly to do so. Visit www.proquest.com or call 1-800-521-0600 to restrict access to your dissertation through ProQuest/UMI.",
        required: true,
        dataField: "disclosureProRequestUnderstanding",
        defaultValue: BOOL.NO,
        choices: [
          {
            id: "pro-request-restrict-understanding-confirmation",
            title: "I understand the following:",
            responseTitle: "Student understands that ProQuest/UMI must be contacted separately to restrict access to dissertations via ProQuest/UMI and other third parties.",
            value: BOOL.YES
          }
        ]
      },
      {
        id: "pro-request-extension-understanding",
        type: "checkbox",
        defaultValue: BOOL.NO,
        summary: "If you require an extension of the restrictions you put on access to your dissertation through ProQuest/UMI, you must contact ProQuest/UMI directly to do so.",
        required: true,
        dataField: "disclosureProRequestConfirmation",
        choices: [
          {
            id: "pro-request-extension-confirmation",
            title: "I understand the following:",
            responseTitle: "Student understands that ProQuest/UMI must be contacted separately to request extensions of restrictions on access via their systems.",
            value: BOOL.YES
          }
        ]
      }
    ]
  }
];