import { generateClient } from "aws-amplify/api";
import { filter, intersection, sortBy, transform, uniqBy } from "lodash";
import { transformGraduateFacultyDropdownOptions } from "./transform";
import { 
    ACADEMIC_UNITS, ADVISORS, CERTIFICATE_PLANS, CO_ADVISORS, PERSON, PROPOSED_ADVISORS, JOB_CODES, DEGREES, DROPDOWNS, FACULTY_MEMBERS,
    GRADUATE_FACULTY_REPRESENTATIVES, GRADUATE_FACULTY_SEARCH, INTERDISCIPLINARY_SPECIALIZATIONS, MINORS, SPECIALIZATIONS,
    getAcademicPrograms,
    STUDENT_INFORMATION, FETCH_USER
} from "../actions-index.js";
import * as Queries from '../graphql/queries';
import * as Custom from '../graphql/custom';
import { ACTION_STATUS, DEGREE, GRAD_LEVEL } from "../util/constants";
import { debouncedOptions, dropdownSubtypes } from "../util/enums.js";
import { buildAction } from "../util/functions";

const API = generateClient();

export function getPerson(emplid) {
    return async (dispatch, getState) => {
        const { person = {} } = getState()
        if(Object.keys(person?.archive?.[emplid] ?? {}).length) {
            return dispatch(buildAction(PERSON, { status: ACTION_STATUS.SUCCESS, data: person.archive[emplid] }));
        }
        try {
            dispatch(buildAction(PERSON, { status: ACTION_STATUS.LOADING }));
            const response = await API.graphql({ query: Custom.getPerson, variables: { emplid }});
            const person = response.data.getPerson;
            console.log("ui/src/Common/actions.js getPerson person ", person);
            if(!person.emplid) {
                dispatch(buildAction(PERSON, { status: ACTION_STATUS.NOT_FOUND }));
            } else {
                dispatch(buildAction(PERSON, { status: ACTION_STATUS.SUCCESS, data: person }));
            }
        } catch(error) {
            console.error("Person Error: ", error);
            dispatch(buildAction(PERSON, { status: ACTION_STATUS.ERROR }));
        }
    };
}

export function resetPerson() {
    return (dispatch) => {
        dispatch(buildAction(PERSON, { status: "" }));
    };
}

// generic function to reset state in Redux
export function resetState(type, payload) {
    return (dispatch) => {
        dispatch(buildAction(type, payload));
    };
}

export function getJobCodes() {
    return async (dispatch) => {
        try {
            dispatch(buildAction(DROPDOWNS, { 
                type: dropdownSubtypes.facultyRank, 
                status: ACTION_STATUS.LOADING
            }));
            dispatch(buildAction(JOB_CODES, { status: ACTION_STATUS.LOADING }));
            const response = await API.graphql({ query: Queries.getJobCodes, variables: {} });
            const jobCodes = (response?.data?.getJobCodes?.jobCodes ?? []);
            if(jobCodes?.length) {
                dispatch(buildAction(DROPDOWNS, { 
                    type: dropdownSubtypes.facultyRank, 
                    data: jobCodes.map(({ jobTitle, jobCode }, index) => ({
                        id: `jobcode-${jobCode}-${index}`,
                        title: jobTitle,
                        value: jobCode
                    }))
                }));
            }
            // const jobCodeMap = {};
                if(JSON.stringify(jobCodes) === '{}') {
                    dispatch(buildAction(JOB_CODES, { status: ACTION_STATUS.NOT_FOUND }));
                    dispatch(buildAction(DROPDOWNS, { 
                        type: dropdownSubtypes.facultyRank, 
                        status: ACTION_STATUS.NOT_FOUND
                    }));
                } else {
                    dispatch(buildAction(JOB_CODES, { status: ACTION_STATUS.SUCCESS, data: jobCodes }));
                }
            // dispatch(buildAction(JOB_CODE_MAP, { status: ACTION_STATUS.SUCCESS, data: jobCodeMap }));
        } catch(error) {
            console.error("Get Job Code Error: ", error);
            dispatch(buildAction(JOB_CODES,{ status: ACTION_STATUS.ERROR }));
        }
    };
}

export function getTerms (options = {}) {
    const { career = "GRAD", showFromPresent, showHistorical} = options
    let variables = { career }
    if(showFromPresent) {
      variables.future = 'Y'
    }
    if(showHistorical) {
      variables.history = 'Y'
    }

    return async dispatch => {
      try {
        const response = await API.graphql({ query: Queries.getTerms, variables });
          const terms = response?.data?.getTerms?.terms
          if(!terms.length) {
            throw new Error('unable to get terms')
          }

          const data = formatTerms({ data: terms, showFromPresent, showHistorical }) 

          dispatch(buildAction(DROPDOWNS, { 
              type: dropdownSubtypes.term, 
              data
          }));
      } catch (error) {
        dispatch(buildAction(DROPDOWNS, { 
          type: dropdownSubtypes.term, 
          status: ACTION_STATUS.NOT_FOUND
      }));
      }
    }
}
function formatTerms(options = {}) {
    const { data = [], showFromPresent, showHistorical } = options
    let slicedData = []
    const indexOfPresent = data.findIndex((values) => {
        return values?.termStatus === 'C'
    })

    if(indexOfPresent === -1) {
        let historical = data.filter(term => term.termStatus === 'H')
        historical = historical.slice(-1 * showHistorical)
        let future = data.filter(term => term.termStatus === 'F')
        future = future.slice(0, showFromPresent)
        slicedData = [...historical, ...future]
    } else {
        let start = showHistorical ? (indexOfPresent - showHistorical) : indexOfPresent
        let end = indexOfPresent + showFromPresent
        slicedData = data.slice(start, end)
    }
    return slicedData.map(item => ({
        id: item?.descr.replace(' ', '-'),
        value: item?.strm,
        title: item?.descr
    }))
}

export function fetchDebouncedOptions(subtype, state = {}, options = {}) {
    return async (dispatch) => {
        const { query = "", filterState, overrideFaculty } = options;
        if(!query || query.length < 2) {
            return await dispatch(buildAction(DROPDOWNS, { 
                type: subtype, 
                status: "MISSING_QUERY",
            }));
        }
        const queries = (state.queries ?? []);
        const dropdownOptions = (state.options ?? []);
        const titles = dropdownOptions.map(dropdownOption => (dropdownOption.selectedTitle ?? dropdownOption.title));

        if(!queries.includes(query) && !titles.includes(query)) {
            if(subtype === dropdownSubtypes.advisor) {
                return await dispatch(searchGraduateFaculty(query, ADVISORS, dropdownSubtypes.advisor, filterState, overrideFaculty));
            }
            if(subtype === dropdownSubtypes.coAdvisor) {
                return await dispatch(searchGraduateFaculty(query, CO_ADVISORS, dropdownSubtypes.coAdvisor, filterState, overrideFaculty));
            }
            if(subtype === dropdownSubtypes.facultyMember) {
                return await dispatch(searchGraduateFaculty(query, FACULTY_MEMBERS, dropdownSubtypes.facultyMember, filterState, overrideFaculty));
            }
            if(subtype === dropdownSubtypes.gradFacultyRepresentative) {
                return await dispatch(searchGraduateFaculty(query, GRADUATE_FACULTY_REPRESENTATIVES, dropdownSubtypes.gradFacultyRepresentative, filterState, overrideFaculty));
            }
            if(subtype === dropdownSubtypes.proposedAdvisor) {
                return await dispatch(searchGraduateFaculty(query, PROPOSED_ADVISORS, dropdownSubtypes.proposedAdvisor, filterState, overrideFaculty));
            }
        }
    }
}

export function refreshDropdowns(subtype, options = {}) {
    return async (dispatch, getState) => {
        try {
            const state = getState()
            const fetchData = checkIfFetchingRequired(state, subtype)
            if(debouncedOptions.includes(subtype)) {
                return await dispatch(fetchDebouncedOptions(subtype, state?.dropdowns?.[subtype], options))
            }
            if(!fetchData) {
                return {}
            }
            if(subtype === dropdownSubtypes.graduatePrograms) {
                return await dispatch(getAcademicPrograms())
            }
            if(subtype === dropdownSubtypes.facultyRank) {
                return await dispatch(getJobCodes())
            }
            if(subtype === dropdownSubtypes.term) {
                return await dispatch(getTerms(options?.filter))
            }
            if(subtype === dropdownSubtypes.degrees) {
                return await dispatch(getDegrees());
            }
            if(subtype === dropdownSubtypes.certicatePlans) {
                return await dispatch(getCertificatePlans());
            }
            if(subtype === dropdownSubtypes.specialization) {
                return await dispatch(getSpecializations());
            }
            if(subtype === dropdownSubtypes.designationMinor) {
                return await dispatch(getMinors());
            }
            if(subtype === dropdownSubtypes.designationGIS) {
                return await dispatch(getInterdisciplinarySpecializations());
            }
            if(subtype === dropdownSubtypes.academicUnit) {
                return await dispatch(getAcademicUnits());
            }
            if(subtype === dropdownSubtypes.gradPlanAdminPrograms) {
                return await dispatch(getGradPlanAdminPrograms());
            }
        } catch (error) {
            console.error(error)
        }
    }
}

const checkIfFetchingRequired = (state, subtype) => {
    const relatedOptions = state?.dropdowns?.[subtype]?.options

    return !relatedOptions?.length
}

export function getDegrees() {
    return async (dispatch) => {
        try {
            dispatch(buildAction(DROPDOWNS, { type: dropdownSubtypes.degrees, status: ACTION_STATUS.LOADING }));
            dispatch(buildAction(DEGREES, { status: ACTION_STATUS.LOADING }));
            const response = await API.graphql({ query: Queries.getDegrees, variables: {} });
            let degrees = (response?.data?.getDegrees?.degrees ?? []);
            degrees = sortBy(degrees, ["gradLevel"]);
            dispatch(buildAction(DROPDOWNS, { 
                type: dropdownSubtypes.degrees,
                status: ACTION_STATUS.SUCCESS,
                data: degrees.map(({ degree, gradLevel }, index) => ({
                    id: `degree-${degree}-${index}`,
                    group: gradLevel,
                    selectedTitle: `${gradLevel} - ${degree}`,
                    title: degree,
                    value: degree
                }))
            }));
            dispatch(buildAction(DEGREES, { status: ACTION_STATUS.SUCCESS, data: degrees }));
        } catch(error) {
            console.error("Get Degrees Error: ", error);
            dispatch(buildAction(DEGREES,{ status: ACTION_STATUS.ERROR }));
        }
    };
}

export function getCertificatePlans() {
    return async (dispatch) => {
        try {
            dispatch(buildAction(DROPDOWNS, { type: dropdownSubtypes.certicatePlans, status: ACTION_STATUS.LOADING }));
            dispatch(buildAction(CERTIFICATE_PLANS, { status: ACTION_STATUS.LOADING }));
            const response = await API.graphql({ query: Queries.getAcademicPlans, variables: { filter: { degree: DEGREE.CERTIFICATE, status: "A" } } });
            const certificatePlans = (response?.data?.getAcademicPlans?.academicPlans ?? []);
            dispatch(buildAction(DROPDOWNS, { 
                type: dropdownSubtypes.certicatePlans,
                status: ACTION_STATUS.SUCCESS,
                data: sortBy(certificatePlans.map(({ academicPlan, descr }, index) => ({
                    id: `certificatePlan-${academicPlan}-${index}`,
                    title: descr,
                    value: academicPlan
                })), ["title"])
            }));
            dispatch(buildAction(CERTIFICATE_PLANS, { status: ACTION_STATUS.SUCCESS, data: certificatePlans }));
        } catch(error) {
            console.error("Get Certificate Plans Error: ", error);
            dispatch(buildAction(CERTIFICATE_PLANS,{ status: ACTION_STATUS.ERROR }));
        }
    };
}

export function getSpecializations() {
    return async (dispatch) => {
        try {
            dispatch(buildAction(DROPDOWNS, { type: dropdownSubtypes.specialization, status: ACTION_STATUS.LOADING }));
            dispatch(buildAction(SPECIALIZATIONS, { status: ACTION_STATUS.LOADING }));
            const response = await API.graphql({ query: Queries.getAcademicPlans, variables: { filter: { degree: DEGREE.SPECIALIZATION, status: "A" } } });
            console.log("ui/src/Common/actions.js getSpecializations response has ", response);
            const specializations = (response?.data?.getAcademicPlans?.academicPlans ?? []);
            console.log("ui/src/Common/actions.js getSpecializations specializations has ", specializations);
            // const specializations = (response?.data?.getAcademicPlans?.graduateProgram ?? []);
            dispatch(buildAction(DROPDOWNS, { 
                type: dropdownSubtypes.specialization,
                status: ACTION_STATUS.SUCCESS,
                data: sortBy(specializations.map(({ academicPlan, graduateProgram, acadSubPlan, descr }, index) => ({
                    id: `specialization-${acadSubPlan}-${index}`,
                    title: `${acadSubPlan} - ${descr}`,
                    value: acadSubPlan,
                    academicPlan // used for filtering
                })), ["title"])
            }));
            dispatch(buildAction(SPECIALIZATIONS, { status: ACTION_STATUS.SUCCESS, data: specializations }));
        } catch(error) {
            console.error("Get Specializations Error: ", error);
            dispatch(buildAction(SPECIALIZATIONS,{ status: ACTION_STATUS.ERROR }));
        }
    };
}

export function getMinors() {
    return async (dispatch) => {
        try {
            dispatch(buildAction(DROPDOWNS, { type: dropdownSubtypes.designationMinor, status: ACTION_STATUS.LOADING }));
            dispatch(buildAction(MINORS, { status: ACTION_STATUS.LOADING }));
            const response = await API.graphql({ query: Queries.getAcademicPlans, variables: { filter: { degree: DEGREE.MINOR, status: "A" } } });
            const minors = (response?.data?.getAcademicPlans?.academicPlans ?? []);
            dispatch(buildAction(DROPDOWNS, { 
                type: dropdownSubtypes.designationMinor,
                status: ACTION_STATUS.SUCCESS, 
                data: sortBy(minors.map(({ academicPlan, descr }, index) => ({
                    id: `minor-${academicPlan}-${index}`,
                    title: `${academicPlan} - ${descr}`,
                    value: academicPlan,
                })), ["title"])
            }));
            dispatch(buildAction(MINORS, { status: ACTION_STATUS.SUCCESS, data: minors }));
        } catch(error) {
            console.error("Get Minors Error: ", error);
            dispatch(buildAction(MINORS,{ status: ACTION_STATUS.ERROR }));
        }
    };
}

export function getInterdisciplinarySpecializations() {
    return async (dispatch) => {
        try {
            dispatch(buildAction(DROPDOWNS, { type: dropdownSubtypes.designationGIS, status: ACTION_STATUS.LOADING }));
            dispatch(buildAction(INTERDISCIPLINARY_SPECIALIZATIONS, { status: ACTION_STATUS.LOADING }));
            const response = await API.graphql({ query: Queries.getAcademicPlans, variables: { filter: { degree: DEGREE.INTERDISCIPLINARY_SPECIALIZATION, status: "A" } } });
            const interdisciplinarySpecializations = (response?.data?.getAcademicPlans?.academicPlans ?? []);
            dispatch(buildAction(DROPDOWNS, { 
                type: dropdownSubtypes.designationGIS,
                status: ACTION_STATUS.SUCCESS,
                data: sortBy(interdisciplinarySpecializations.map(({ academicPlan, descr }, index) => ({
                    id: `interdisciplinary-specialization-${academicPlan}-${index}`,
                    title: `${academicPlan} - ${descr}`,
                    value: academicPlan,
                })), ["title"])
            }));
            dispatch(buildAction(INTERDISCIPLINARY_SPECIALIZATIONS, { status: ACTION_STATUS.SUCCESS, data: interdisciplinarySpecializations }));
        } catch(error) {
            console.error("Get Interdisciplinary Specializations Error: ", error);
            dispatch(buildAction(INTERDISCIPLINARY_SPECIALIZATIONS,{ status: ACTION_STATUS.ERROR }));
        }
    };
}

export function getAcademicUnits() {
    return async (dispatch) => {
        try {
            dispatch(buildAction(DROPDOWNS, { type: dropdownSubtypes.academicUnit, status: ACTION_STATUS.LOADING }));
            dispatch(buildAction(ACADEMIC_UNITS, { status: ACTION_STATUS.LOADING }));
            const response = await API.graphql({ query: Queries.getAcademicPlans, variables: { filter: { status: "A" } } });
            let academicUnits = (response?.data?.getAcademicPlans?.academicPlans ?? []);
            academicUnits = academicUnits.filter(academicUnit => {
                return (
                        intersection([GRAD_LEVEL.DMA, GRAD_LEVEL.DOCTORAL, GRAD_LEVEL.EDUCATIONAL_SPECIALIST, GRAD_LEVEL.MASTER, GRAD_LEVEL.MASTERCB, GRAD_LEVEL.PROFESSIONAL], academicUnit.gradLevel).length > 0 &&
                    academicUnit.acadSubPlan === null
                );
            });
            academicUnits = uniqBy(academicUnits, "graduateProgram");
            dispatch(buildAction(DROPDOWNS, { 
                type: dropdownSubtypes.academicUnit,
                status: ACTION_STATUS.SUCCESS, 
                data: sortBy(academicUnits.map(({ graduateProgram, descr }, index) => ({
                    id: `academic-unit-${graduateProgram}-${index}`,
                    title: `${graduateProgram} - ${descr}`,
                    value: graduateProgram,
                })), ["title"])
            }));
            dispatch(buildAction(ACADEMIC_UNITS, { status: ACTION_STATUS.SUCCESS, data: academicUnits }));
        } catch(error) {
            console.error("Get Academic Units Error: ", error);
            dispatch(buildAction(ACADEMIC_UNITS,{ status: ACTION_STATUS.ERROR }));
        }
    };
}

export function searchGraduateFaculty(query, action = GRADUATE_FACULTY_SEARCH, dropdownSubtype = null, filterState, overrideFaculty) {
    return async (dispatch) => {
        try {
            if(dropdownSubtype) dispatch(buildAction(DROPDOWNS, { type: dropdownSubtype, status: ACTION_STATUS.LOADING, query }));
            dispatch(buildAction(action, { status: ACTION_STATUS.LOADING }));
            let graduateFaculty = overrideFaculty

            if(!graduateFaculty && query) {
                const response = await API.graphql({ query: Queries.searchGraduateFaculty, variables: { query } });
                graduateFaculty = (response?.data?.searchGraduateFaculty?.graduateFaculty ?? []);
            }         

            let data = transformGraduateFacultyDropdownOptions(graduateFaculty, !!overrideFaculty?.length);
            if(filterState) {
                const dataFilter = transform(filterState, (result, filterValue) => {
                    result[filterValue.dataField] = filterValue.value[filterValue.dataField];
                }, {});
                data = filter(data, dataFilter);
            }
            if(dropdownSubtype) {
                dispatch(buildAction(DROPDOWNS, {
                    type: dropdownSubtype,
                    status: ACTION_STATUS.SUCCESS,
                    data,
                    query
                }));
            }
            dispatch(buildAction(action, { status: ACTION_STATUS.SUCCESS, data }));
        } catch(error) {
            console.error("Search Graduate Faculty Error: ", error);
            dispatch(buildAction(action,{ status: ACTION_STATUS.ERROR }));
        }
    };
}

export function getStudentInfo(studentid) {
    return async (dispatch) => {
        try {
            dispatch(buildAction(STUDENT_INFORMATION, { status: ACTION_STATUS.LOADING }));
            console.log("************ /ui/src/Common/actions.js getStudentInfo studentid is ", studentid);
            const response = await API.graphql({ query: Custom.getStudentInfo, variables: { studentid } });
            console.log("************ /ui/src/Common/actions.js getStudentInfo response is ", response);
            if(response?.data?.getStudentInfo?.record?.osuid) {
                dispatch(buildAction(STUDENT_INFORMATION, response?.data?.getStudentInfo?.record));
            } else {
                throw new Error(`Unexpected response: ${response}`)
            }
        } catch (error) {
            dispatch(buildAction(STUDENT_INFORMATION, { status: ACTION_STATUS.ERROR }));
            console.error(error)
        }
    }
}

export function getGradPlanAdminPrograms() {
    return async (dispatch, getState) => {
        const { authentication } = getState();
        const gpaPrograms = authentication?.user?.gradPlanAdminPrograms;
        try {
            dispatch(buildAction(DROPDOWNS, { type: dropdownSubtypes.academicUnit, status: ACTION_STATUS.LOADING }));
            dispatch(buildAction(ACADEMIC_UNITS, { status: ACTION_STATUS.LOADING }));
            let academicUnits = [];
            for (let program of gpaPrograms) {
                const response = await API.graphql({ query: Queries.getAcademicPlans, variables: { filter: { program: program, status: "A" } } });
                let academicUnit = (response?.data?.getAcademicPlans?.academicPlans ?? []);
                academicUnit = academicUnit.filter(acadUnit => {
                    return (
                            intersection([GRAD_LEVEL.DMA, GRAD_LEVEL.DOCTORAL, GRAD_LEVEL.EDUCATIONAL_SPECIALIST, GRAD_LEVEL.MASTER, GRAD_LEVEL.MASTERCB, GRAD_LEVEL.PROFESSIONAL,GRAD_LEVEL.INTERDISC_SPEC], acadUnit.gradLevel).length > 0 &&
                            acadUnit.acadSubPlan === null
                    );
                });
                academicUnit = uniqBy(academicUnit, "graduateProgram");
                if (academicUnit.length > 0) {
                    academicUnits.push(academicUnit[0]);
                }    
            }
            dispatch(buildAction(DROPDOWNS, { 
                type: dropdownSubtypes.gradPlanAdminPrograms,
                status: ACTION_STATUS.SUCCESS, 
                data: sortBy(academicUnits.map(({ graduateProgram, descr }, index) => ({
                    id: `academic-unit-${graduateProgram}-${index}`,
                    title: `${graduateProgram} - ${descr}`,
                    value: graduateProgram,
                })), ["title"])
            }));
            dispatch(buildAction(ACADEMIC_UNITS, { status: ACTION_STATUS.SUCCESS, data: academicUnits }));
        } catch(error) {
            console.error("Get Grad Plan Admin Programs Error: ", error);
            dispatch(buildAction(ACADEMIC_UNITS,{ status: ACTION_STATUS.ERROR }));
        }
    };
}
