import { Mui } from "@osu/react-ui";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { MAX_LENGTH_EMPLID } from "../../../util/constants";
import { isValidEmplidLength } from "../../../util/functions";
import useLookup from "../../hooks/useLookup";

const LoadableTextField = (props = {}) => {
  const { loading, value, className, ...rest } = props;

  if (loading) {
    return <Mui.Skeleton height={80} width={200} className={className} />;
  }
  if (!value) {
    return null;
  }
  let cls = className ? className : ''
  cls += " margin-top-2"

  return (
    <Mui.TextField
      className={cls}
      variant="filled"
      InputProps={{
        readOnly: true,
      }}
      InputLabelProps={{ shrink: true }}
      value={value}
      {...rest}
    />
  );
};

const Results = (props = {}) => {
  const { firstName, id, lastName, phone, facultyEmail, loading, student } = props;
  let _results = <Fragment>
      <LoadableTextField
        id={`${id}-first-name`}
        label="First Name"
        loading={loading}
        value={firstName}
        className="margin-right-1"
      />
      <LoadableTextField
        id={`${id}-last-name`}
        label="Last Name"
        loading={loading}
        value={lastName}
        className="margin-right-1"
      />
      {!student && <LoadableTextField
        id={`${id}-phone`}
        label="Phone"
        loading={loading}
        value={phone}
        className="margin-right-1"
      />}
      {!student && <LoadableTextField
        id={`${id}-faculty-email`}
        label="Faculty Email"
        loading={loading}
        value={facultyEmail}
      />}
  </Fragment>

  return (
    <Mui.Box className="margin-left-2" display="flex" flexDirection="row">
      {_results}
    </Mui.Box>
  );
};

function Lookup(props = {}) {
  const { id, value: val, onChange, student, className, facultyNomination = false } = props;
  const lookupButton = useRef();
  const [emplid, setEmplid] = useState("");
  const [lookupClicked, setLookUpClicked] = useState(false);
  const [invalidFacultyNominee, setInvalidFacultyNominee] = useState(false);
  const { person = {}, getPerson, setPersonEmplid, status } = useLookup({ student });
  const loading = status === "loading";
  const value = Object.prototype.hasOwnProperty.call(val || {}, 'emplid') ? val.emplid : val
  const updateEmpl = (value) && value !== emplid
  const triggerChange = lookupClicked && person?.emplid
  const error = (['notfound', 'error'].includes(status));

  useEffect(() => {
    if(updateEmpl) {
      setEmplid(value);
      setPersonEmplid(value);
    }
  }, [setPersonEmplid, updateEmpl, value])

  useEffect(() => {
    if(triggerChange) {
      onChange(person)
      setLookUpClicked(false)
    }
  }, [onChange, person, triggerChange])

  useEffect(() => {
    if(facultyNomination === true && person?.emplid) {
      const isInvalidFacultyNominee = (person.facultyNomination !== true);
      if(invalidFacultyNominee !== isInvalidFacultyNominee) {
        setInvalidFacultyNominee(isInvalidFacultyNominee);
      }
    }
  }, [facultyNomination, invalidFacultyNominee, person])

  let LookupResults = null;
  if(invalidFacultyNominee) {
    LookupResults = (
      <Mui.Alert className="margin-top-2" variant="outlined" severity="error">
        {emplid} is not eligible for grad faculty nomination.
      </Mui.Alert>
    );
  } else if(error) {
    LookupResults = (
      <Mui.Alert className="margin-top-2" variant="outlined" severity="error">
        The OSU ID entered did not return any results. Please re-check your OSU ID and try again.
      </Mui.Alert>
    );
  } else {
    LookupResults = (<Results student={student} id={id} loading={loading} {...val || {}} {...person}  />);
  }

  return (
    <div className={className}>
      <Mui.ButtonGroup disableRipple>
        <Mui.Button
          id="textField1"
          label="OSU ID"
          marginleft={5}
          value={value}
          disabled={loading}
          variant="outlined"
          maxLength={MAX_LENGTH_EMPLID}
          onChange={(e) => {
            let value = e.target.value;
            value = value.replace(/\D/g, ""); // remove non-digits
            value = value.substring(0, MAX_LENGTH_EMPLID);
            onChange({
              emplid: value,
            });
            setEmplid(value);
          }}>
          <Mui.TextField variant="outlined" value={value} inputProps={{
              onKeyDown: (e) => {
                if(e.key === "Enter") {
                  e.preventDefault(); // prevent form submission
                  // click the lookup button when enabled
                  if(lookupButton?.current && lookupButton.current?.disabled === false) {
                    lookupButton.current.click();
                  }
                }
              }
            }} />
        </Mui.Button>
        <Mui.Button
          ref={lookupButton}
          color="primary"
          variant="contained"
          disabled={loading || !emplid || !isValidEmplidLength(emplid)}
          onClick={() => {
            setLookUpClicked(true)
            getPerson(emplid);
          }}
          endIcon={
            loading && <Mui.CircularProgress color="inherit" size={20} />
          }
        >
          Lookup {student ? "Student" : "Faculty"}
        </Mui.Button>
      </Mui.ButtonGroup>
      {LookupResults}
    </div>
  );
}

export default Lookup;
