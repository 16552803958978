import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { invert } from "lodash";
import useFormSubmission from "../hooks/useFormSubmission";
import { pendingFormExists } from "../queries";
import { FORM_TYPE_HTML_FRIENDLY } from "../../util/constants";

export default function GraduateFacultyNomination(props = {}) {
  const { Component, match = {} } = props;
  const { params = {}, pathname = "" } = match;
  const { formId, formType: formTypeFriendly } = params;
  const formType = invert(FORM_TYPE_HTML_FRIENDLY)[formTypeFriendly];
  const isCreate = pathname.includes("/create");
  const isEdit = pathname.includes("/edit");
  
  const form = useSelector((state) => (state?.form?.[formId]));
  const user = useSelector((state) => (state?.authentication?.user ?? {}));
  const { academicPlan = null } = user;
  const osuid = (params?.osuid ?? user.osuid);
  const { navigateToFormLandingPage, validateEditPermitted } = useFormSubmission({ osuid });
  const [hasValidated, setValidated] = useState(false);
  const [isValidating, setValidating] = useState(true);

  // const navigate = useNavigate();
  // const { academicPlan = null, hasDoctoralAcademicPlan = false, osuid } = user;
  
  // const validateSubmit = useCallback(async () => {  
  //     const hasPendingForm = await pendingFormExists({ academicPlans: [academicPlan], formType, studentid: osuid }, false);
  //     if(hasPendingForm) return "Unable to submit Grad Faculty Nomination. There is an existing Pending or Approved Form with the Graduate School."
  //     return null;
  // }, [academicPlan, formType, osuid]);

  const validateCreate = useCallback(async () => {
    if(!osuid) return navigateToFormLandingPage(formTypeFriendly, "Unable to find faculty member. Please contact your graduate office.");
    const hasPendingForm = await pendingFormExists({ formType, studentid: osuid }, true);
    if(hasPendingForm) return navigateToFormLandingPage(formTypeFriendly, `There is an existing Draft, Pending or Approved Graduate Nomination form for ${osuid}.`);
  }, [formType, formTypeFriendly, navigateToFormLandingPage, osuid]);

 // validate create
 useEffect(() => {
  if(isCreate && hasValidated === false && isValidating === true) {
    validateCreate();
    setValidated(true);
    setValidating(false);
  }
}, [hasValidated, isCreate, isValidating, validateCreate]);

// validate edit
useEffect(() => {
  if(isEdit && hasValidated === false && isValidating === true && form) {
    if(form?.status !== "error") {
      const error = validateEditPermitted(form);
      if(error) navigateToFormLandingPage(formTypeFriendly, error);
    }
    setValidated(true);
    setValidating(false);
  }
}, [form, formTypeFriendly, hasValidated, isEdit, isValidating, navigateToFormLandingPage, validateEditPermitted]);


return (<Component {...props} isValidating={isValidating} />);
}