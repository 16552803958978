import { generateClient } from "aws-amplify/api";
import isDate from "date-fns/isDate";
import { compact, filter as lodashFilter, flatten, find, forOwn, isEmpty, isEqual, reduce } from "lodash";
import { constructMember, transformLandingForms, transformValueWithGraphqlMapping } from "./transform";
import {
  INDIVIDUAL_FORM,
  INDIVIDUAL_FORM_EDITABLE,
  INDIVIDUAL_FORM_FORMATTED,
  INDIVIDUAL_FORM_HISTORY,
  LANDING_FORMS,
  refreshDropdowns,
  fetchDebouncedOptions,
  PERSON,
  STUDENT_INFORMATION,
  FORM_ACTION,
  TASK_ACTION
} from "../actions-index";
import { constructPersonString, formatDropdownSubtypeFromState } from "../Common/transform";
import { getFormByType } from "../Forms/paths";
import * as Queries from "../graphql/queries";
import * as Mutations from "../graphql/mutations";
import * as Custom from "../graphql/custom";
import { generateAction } from "../redux-helpers";
import { ACTION_STATUS, AWS_LAMBDA, BOOL, DATE_FORMAT, ERROR_TYPE, personInfo, TIME_FORMAT } from "../util/constants";
import { debouncedOptions, graduateFacultyOptions, inputMappings, loggedinUserData } from "../util/enums";
import {
  buildAction,
  createId,
  formatDate,
  getSyncOptions,
  isAsyncDropdownSubtype,
  isSyncDropdownSubtype,
} from "../util/functions";
import { createFormTypeBasedFormRequest } from "../graphql/custom";
import { format, isValid } from "date-fns";
import { LOOKUP_NAME_BASE } from "../Common/components/Inputs/constants";
import Address from "../Common/components/Person/components/Address";

const API = generateClient();
const newId = createId('data-fetched')

export function getForm(formType, formId, studentid) {
  const formRequest = createFormTypeBasedFormRequest(formType, true)

  return async (dispatch) => {
    try {
      const response = await API.graphql({
        query: formRequest,
        variables: {
          formType,
          formId,
          studentid
        },
      });

      const form = response?.data?.getForm?.form;
      if (form?.formType && form?.id) {
        dispatch(generateAction(INDIVIDUAL_FORM, form));
      } else {
        throw new Error(`Unable to parse response from get forms`, response);
      }
      return form;
    } catch (error) {
      console.error(error);
      let navigationMessage = null;
      if(error?.errors?.[0]?.errorType === ERROR_TYPE.VALIDATION) {
        navigationMessage = error.errors[0].message;
      }
      dispatch(generateAction(INDIVIDUAL_FORM, {
        navigationMessage,
        id: formId,
        status: "error"
      }));
    }
  };
}

function getFormDropdownValue({ subtype, state = {}, choices, value, formId, question, filter, resultValues = [] }) {
  const { dropdowns } = state
  return async (dispatch, getState) => {
    let options = [];
    if (choices?.length) {
      options = choices;
    } else if(graduateFacultyOptions.includes(subtype)) {
      const { options: opts } = formatDropdownSubtypeFromState({ subtype, state: { dropdowns } });
      options = opts;
      value = reduce(value, (result, value, key) => {
        if(value !== null) result[key] = value;
        return result;
      }, {});
    } else if (isAsyncDropdownSubtype(subtype)) {
      await dispatch(refreshDropdowns(subtype, { filter }));
      const { dropdowns: refetchedDropdowns } = getState();
      const { options: opts } = formatDropdownSubtypeFromState({
        subtype,
        dropdowns: refetchedDropdowns
      });
      options = opts;
    } else if (isSyncDropdownSubtype(subtype)) {
      options = getSyncOptions(subtype);
    }
    let relatedOption
    let filteredOptions = options.filter(({ value: optVal }) => {
      return ((optVal === value) || (isEqual(optVal, value)))
    });

    const resultsPredicate = reduce(resultValues, (result, resultValue) => {
      forOwn(resultValue, (value) => {
        result.push(value);
      });
      return result;
    }, []);
    if(resultsPredicate.length > 0) filteredOptions = lodashFilter(filteredOptions, resultsPredicate);

    if(filteredOptions.length === 1) {
      relatedOption = filteredOptions[0]
    } else {
      const otherValues = state?.form?.[formId]
      const furtherFilteredOptions = filteredOptions.filter((optionRemaining => {
        const results = question?.results || []
        const everyResultMatches = results.every(({ field = '', dataField = ''}) => {
          return optionRemaining?.[field] && (optionRemaining[field] === otherValues?.[dataField])
        })

        return everyResultMatches
      }))
      relatedOption = furtherFilteredOptions?.[0]
    }

    return relatedOption ?? value;
  };
}

const updateValue = ({ form, value: val, state, question }) => {
  const { dataField = "", type, subtype, choices, filter, questions = [], results = [] } = question;
  let value = val;

  const getFormValue = (form, dataField) => {
    let formValue;
  
    let splitDataField = dataField.split(".");
    if(splitDataField?.length > 1) {
      for(const field of splitDataField) {
        formValue = (formValue ? formValue[field] : form[field]);
      }
    }

    if(splitDataField?.length === 1) {
      splitDataField = dataField?.split("#");
      if(splitDataField?.length > 1) {
        const skType = splitDataField[1];
        formValue = form[splitDataField[0]].filter(field => (field.skType === skType));
      }
    }

    if(splitDataField?.length === 1) {
      formValue = form[dataField];
    }

    return formValue
  }
  const formValue = getFormValue(form, dataField);
  const resultValues = results.map(result => {
    return { field: result.field, value: getFormValue(form, result.dataField) };
  });

  return async (dispatch) => {
    if(type === inputMappings.inputGroup) {
      let valArr = []
      const inputtedValues = form?.[dataField] || formValue || []
      let questionIdex = 0
      let preCalledOptions = []
      let dataFieldMapping = {}
      for (const inputGroupQuestion of questions) {
        const { dataField } = inputGroupQuestion
        let userInputIndex = 0
        let newInputItemValue = {}
        for(const userInput of inputtedValues) {
          if(inputGroupQuestion.id) {
            dataFieldMapping[inputGroupQuestion.id] = dataField
          }
          let formInputtedValue = dataField ? userInput[dataField] : userInput
          newInputItemValue.required = inputGroupQuestion?.required
          newInputItemValue.id = inputGroupQuestion?.id + "-choice-" + userInputIndex + questionIdex
          newInputItemValue.initial = false
          if(inputGroupQuestion.skType) newInputItemValue.skType = inputGroupQuestion.skType;
          if(!valArr[userInputIndex]) valArr[userInputIndex] = {}
          if(inputGroupQuestion.type === inputMappings.dropDown && debouncedOptions?.includes(inputGroupQuestion?.subtype)) {
            newInputItemValue.value = constructMember(formInputtedValue.value)
            newInputItemValue[inputGroupQuestion.id] = newInputItemValue.value
            const lastName = newInputItemValue[inputGroupQuestion.id]?.lastName
            if(newInputItemValue?.value) {
              if(!preCalledOptions[questionIdex]) preCalledOptions[questionIdex] = {}
              preCalledOptions[questionIdex].subtype = inputGroupQuestion?.subtype
              if(!preCalledOptions[questionIdex].options) preCalledOptions[questionIdex].options = {}
              preCalledOptions[questionIdex].options.filter = filter    
              preCalledOptions[questionIdex].options.query = lastName    
              if(!preCalledOptions[questionIdex].options.overrideFaculty) {
                preCalledOptions[questionIdex].options.overrideFaculty = []
              }
              preCalledOptions[questionIdex].options.overrideFaculty.push(newInputItemValue.value)
            }

          } else if(inputGroupQuestion.type === inputMappings.dropDown)  {
            formInputtedValue = await dispatch(
              getFormDropdownValue({ 
                question: inputGroupQuestion,
                subtype: inputGroupQuestion.subtype, 
                state,
                formId: form?.id,
                value:formInputtedValue,
                 choices, 
                 filter
                })
            );
          } else if(inputGroupQuestion.type === inputMappings.nameNLookup) {
              await dispatch(buildAction(PERSON, { status: ACTION_STATUS.SUCCESS, data: formInputtedValue }));
          }
          newInputItemValue[inputGroupQuestion.id] = formInputtedValue
          newInputItemValue.value = formInputtedValue

          valArr[userInputIndex] = {
            ...valArr[userInputIndex] || {},
            ...newInputItemValue || {}
          }

          userInputIndex++
        }

        questionIdex++

      }    
      
      if(preCalledOptions?.length) {
        for(const fetchableOPtion of preCalledOptions) {
          const { options, state, subtype } = fetchableOPtion

          await dispatch(fetchDebouncedOptions(subtype, state?.dropdowns?.[subtype], options))
        }
      }

      return valArr.map(item => ({
        ...item,
        dataFieldMapping
      }))
    } 
    if (choices?.length) {
      const choice = choices.find(({ value: val }) => {
        return val === value
      });
      if (choice) value = choice;
    }

    if (formValue && type === inputMappings.dropDown) {
      let response = await dispatch(
        getFormDropdownValue({ subtype, question, state, value: (value ?? formValue), choices, filter, formId: form?.id, resultValues })
      );

      if (response) value = response;
      if( graduateFacultyOptions.includes(subtype))  {
        value = constructMember(value?.value || value)
      }
    } else if(type === inputMappings.datetime) {
      value = reduce(value, (result, value, key) => {
        result[key] = value;
        return result;
      }, {});
    }

    return value
  };
};

const committeeForms = (dataField, label, committee, question) => {
  const splitDataField = dataField.split(".")
  const field = splitDataField?.[1];
  return async (dispatch) => {
    let response = {};
    let edit = { value: {} }
    if (dataField?.startsWith("committee")) {
      let value = committee?.[field]
      let valueArr = []
      let qIdx = 0
      if(Array.isArray(value) && value?.length) {
        let nestedViewValues = []
        for(const innerVal of value) {
          const nestedValues = Object.entries(innerVal)

          for (const a of nestedValues) {
            const parsedKey = a?.[0]
            const parsedObjVal = a?.[1]
            const matchingQuestion = question?.questions.find(({
              dataField
            }) => (dataField === parsedKey))
              let newVal = await dispatch(updateValue({
                form: innerVal,
                value: parsedObjVal,
                question
              }))

              if(!edit.value[qIdx]) edit.value[qIdx] = {}
              if(matchingQuestion) {
                edit.value[qIdx][matchingQuestion.id] = newVal
                edit.value[qIdx].id = newId + parsedKey + matchingQuestion.id  + qIdx
                edit.value[qIdx].required= matchingQuestion.required
                nestedViewValues.push({
                  label: matchingQuestion?.title ?? dataField,
                  value: newVal
                })
              }
            }
          }

          valueArr.push(nestedViewValues)

          qIdx++
        
        value = valueArr
      }
      
      if(valueArr?.length) {
        response = {
          id: newId + dataField,
          title: question?.title,
          responses: valueArr
        }
      } else if (field) {
        response = {
          label,
          value
        };
      }
      
    return {
      view: response,
      edit
    };
  }
  };
}

const createNestedItems = (dataField, form, questions, skType) => {
  return async (dispatch) => {
    let relatedFormValues = dataField && dataField.split(".").reduce((a, prop) => a[prop], form)

    if(skType) {
      relatedFormValues = relatedFormValues.filter(value => {
        return value.skType === skType
      })
    }
    let innerValues = [];
    let innerValue;
    let edit = {};
    for (const que of questions ?? []) {
      let idx = 0;
      if (Array.isArray(relatedFormValues) && relatedFormValues.length > 0) {
        for (const field of relatedFormValues) {
          if(field.emplid && graduateFacultyOptions.includes(que.subtype))  {    
            innerValue = constructMember(field)
          } else if(field.emplid && que.type === inputMappings.nameNLookup) {
            innerValue = { title: constructPersonString(field), [que.id]: field };
          } else {
            innerValue = await dispatch(
              updateValue({
                form: field,
                value: field[que.dataField],
                question: que,
              })
            );
          }

          if (!innerValues[idx]) {
            innerValues[idx] = [];
          }
          innerValues[idx].push({
            label: que?.title,
            value: innerValue?.selectedTitle ?? innerValue?.title ?? innerValue,
          });

          edit[idx] = {
            id: newId + que.id + `${idx}`,
            ...(edit[idx] ?? {}),
            [que?.id]: innerValue,
            dataFieldMapping: {
              ...(edit[idx]?.dataFieldMapping ?? {}),
              [que?.id]: que?.dataField,
            },
          };

          idx++;
        }
      } else {
        innerValue = await dispatch(
          updateValue({
            form,
            value: form[que.dataField],
            question: que,
          })
        );

        if (!innerValues[idx]) {
          innerValues[idx] = [];
        }
        innerValues[idx].push({
          label: que?.title,
          value: innerValue?.selectedTitle ?? innerValue?.title ?? innerValue,
        });
        
        edit[idx] = {
          id: newId + que.id + `${idx}`,
          ...(edit[idx] ?? {}),
          [que?.id]: innerValue,
          dataFieldMapping: {
            ...(edit[idx]?.dataFieldMapping ?? {}),
            [que?.id]: que?.dataField,
          },
        };

        idx++;
      }
    }

    return {
      view: innerValues,
      edit,
    };
  };
};

function formatViewForDates(value, question) {
  let responseValue
  let validateAndFormat = (date, formatString) => {
    if(!date) return "-"
    let initializedDate = new Date(date)
    if(formatString && isValid(initializedDate)) {
      return format(initializedDate, formatString)
    }
    return "-"
  }

  if (inputMappings.date === question.type) {
    responseValue = validateAndFormat(value, DATE_FORMAT)
  }  else if (inputMappings.datetime === question.type) {
    let dateValues = []
    if(value?.date) {
      dateValues.push({
        label: 'Date',
        value: validateAndFormat(value?.date, DATE_FORMAT)
      })
    }
    if(question.startTime) {
      dateValues.push({
        label: 'Start Time',
        value: validateAndFormat(value.start, TIME_FORMAT)
      })
    }
    if(question.endTime) {
      dateValues.push({
        label: 'End Time',
        value: validateAndFormat(value.end, TIME_FORMAT)
      })
    }

    responseValue = dateValues

  }

  return responseValue
}

function iterateSections({ sections, state, form }) {
  let committeeResponses = [];
  let formattedSections = [];
  let editForm = {};
  return async (dispatch) => {
    for (const section of sections) {
      let responses = [];
      if (!editForm[section.id]) {
        editForm[section.id] = {};
      }
      let nested = [];
      if (section.questions) {
        for (const question of section.questions) {
          
          const { title, dataField: df = "", dependencies = [], subtype, choices, hiddenResponse = false } = question;

          // hide fields if dependencies aren't met
          if(dependencies.length > 0) {
            let showQuestion = true;
            for(const dependency of dependencies) {
              for(const q of section.questions) {
                if(q.id === dependency && q.choices?.length) {
                  const c = find(q.choices, ["branch", question.id]);
                  if(c) {
                    const matchingQ = editForm[section.id][dependency];
                    if(matchingQ) {
                      const value = (matchingQ?.value?.value || matchingQ?.value);
                      if(value !== c.value) {
                        showQuestion = false;
                        break;
                      }
                    }
                  }
                }
              }
              if(!showQuestion) break;
            }
            if(!showQuestion) continue;
          }

          const splitDataField = df.includes("#") ? df.split("#") : []
          const dataField = splitDataField[0] || df
          if (!editForm[section.id][question.id]) {
            editForm[section.id][question.id] = {};
          }

          let value = ""
          if(question?.graphql) {
            const { edit, read } = transformValueWithGraphqlMapping(form, question)
            responses = responses.concat(read)
            editForm[section.id][question.id] = {
              value: edit
            };
          } else if (dataField) {
            value = await dispatch(
              updateValue({ form: form, state, value: form?.[dataField], question })
              );

            if(splitDataField?.[1] && Array.isArray(value)) {
              value = value.filter(item => {
                return item.skType === splitDataField[1]
              })

            }
            /* if (!value && dataField?.startsWith("committee")) {
              const committeeItem = await dispatch(committeeForms(
                dataField,
                title,
                form?.committee,
                question
              ));

              if (committeeItem?.view) {
                committeeResponses.push(committeeItem.view);
              }
              if (committeeItem?.edit) {
                editForm[section.id][question.id] = committeeItem.edit
              }
            } else  */if (!value && question?.questions) {
              const innerResponses = await dispatch(
                createNestedItems(dataField, form, question.questions, splitDataField?.[1])
              );

              if(innerResponses?.view?.length) {
                nested.push({
                  id: question.id,
                  title: question?.title,
                  titleProps: {
                    component: "h5",
                    variant: "h5"
                  },
                  responses: innerResponses.view,
                });
              }

              if (innerResponses?.edit) {

                editForm[section.id][question.id] = {
                  dataField: df,
                  value: innerResponses.edit,
                };
              }
            } else {
              let responseValue = value?.title ?? value

              if(graduateFacultyOptions.includes(question?.subtype)) {
                responseValue = Object.entries(personInfo.read).map((entry) => {
                  const dataFieldToRender = entry[0]
                  const friendlyLabel = entry[1]
                  let dataFieldValue = value?.value?.[dataFieldToRender];
                  const transform = personInfo.transform[dataFieldToRender];
                  if(transform) dataFieldValue = transform(dataFieldValue);

                  return {
                    label: friendlyLabel,
                    value: dataFieldValue
                  }
                })
              } else if(question?.questions) {
                const innerResponses = await dispatch(
                  createNestedItems(dataField, form, question.questions, splitDataField?.[1])
                );

                if(innerResponses?.view?.length) {
                  responseValue = innerResponses.view
                }
                if(innerResponses?.edit) value = innerResponses.edit;
              } else if([inputMappings.date, inputMappings.datetime].includes(question.type)) {
                responseValue = formatViewForDates(value, question)
              } else if(question.type === inputMappings.checkbox) {
                responseValue = (value?.value ?? value);
              }
              
              let formattedTitle = title
              const checkboxChoices = ((question?.type === inputMappings.checkbox) && question?.choices) || []

              if(!formattedTitle && checkboxChoices?.length === 1) {
                formattedTitle = (question.choices[0]?.responseTitle ?? question.choices[0]?.title);

                if(responseValue && [BOOL.YES.toLowerCase(), BOOL.NO.toLowerCase()].includes(responseValue.toLowerCase())) {
                  value = responseValue;
                  responseValue = (responseValue.toLowerCase() === BOOL.YES.toLowerCase()) ? 'Yes' : 'No'
                }
              }
              responses.push({
                label: formattedTitle,
                value: responseValue,
                hidden: hiddenResponse
              });

              editForm[section.id][question.id] = {
                dataField: df,
                value,
              };

              if(value?.branch && choices.length) {
                const branchedValue = choices.find(choice => choice.id === value.branch)
                if(branchedValue) {
                  const innerBranchedValue = await dispatch(
                    updateValue({ form: form, state, value: form?.[value.branch], question: branchedValue })
                  );

                  let responseValue = innerBranchedValue?.title ?? innerBranchedValue?.value
                  if(!responseValue && branchedValue?.type === loggedinUserData.mailingAddress) {
                    const address =  branchedValue?.dataField ? form[branchedValue.dataField] : {}
                    responseValue = address?.address1 ? <Address className="padding-2 margin-2" {...address} /> : '-'
                  }

                  responses.push({label: branchedValue.title, value: responseValue});
    
                  if(!editForm[section.id][value.branch]) {
                    editForm[section.id][value.branch] = {}
                  }
                  
                  editForm[section.id][value.branch].value = innerBranchedValue
                  editForm[section.id][value.branch].dataField = branchedValue?.dataField
                }
              }
            }
          } else if (!dataField && question?.questions) {
            const innerResponses = await dispatch(
              createNestedItems(dataField, form, question.questions, splitDataField?.[1])
            );

            if(innerResponses?.view?.length) {
              nested.push({
                id: question.id,
                title: question?.title,
                titleProps: {
                  component: "h5",
                  variant: "h5"
                },
                responses: innerResponses.view,
              });
            }

            if (innerResponses?.edit) {
              editForm[section.id][question.id] = {
                dataField: df,
                value: innerResponses.edit,
              };
            }
          }

          if(Array.isArray(question?.results)) {
            question.results.forEach(({ label: resultLabel, dataField: resultDF, field: resultField }) => {
              editForm[section.id][resultField] = {
                value: form[resultDF],
                dataField: resultDF
              }
            })
          }
          
          if(question?.type === inputMappings.lookup) {
            if(question?.student) {
              dispatch(buildAction(PERSON, { status: ACTION_STATUS.SUCCESS, data: {
                emplid: form?.studentid,
                name: form?.name
              } }));
              editForm[section.id][LOOKUP_NAME_BASE.id] = Object.assign({}, LOOKUP_NAME_BASE, {
                value: form[LOOKUP_NAME_BASE.dataField]
              })
              editForm[section.id][question.id] = Object.assign({}, LOOKUP_NAME_BASE, {
                id: question.id,
                dataField: question.dataField || question.id || "osuid",
                value: form.studentid
              })
            }
            if(question?.facultyNomination) {
              dispatch(buildAction(PERSON, { status: ACTION_STATUS.SUCCESS, data: form.facultyMember }));
              editForm[section.id][question.id] = {
                id: question.id,
                dataField: question.dataField || question.id || "facultyMember",
                value: form.facultyMember
              }
            }
          }
        }
      }

      if (responses?.length) {
        formattedSections.push({
          id: section?.id,
          title: section?.title,
          responses,
        });

        if(nested) {
          formattedSections = formattedSections.concat(nested)
        }
      }
    }
    /* if (committeeResponses?.length) {
      formattedSections.push({
        id: "committee",
        title: "Committee",
        wrap: false,
        responses: committeeResponses,
      });
    } */
    return {
      formattedSections: [...formattedSections],
      editForm,
    };
  };
}

export function getSections({ id, osuid, ...rest }) {
  return async (dispatch, getState) => {
    dispatch(
      generateAction(INDIVIDUAL_FORM_FORMATTED, {
        id,
        status: "initialized",
      })
    );
    dispatch(
      generateAction(INDIVIDUAL_FORM_EDITABLE, {
        id,
        status: "initialized",
      })
    );
    try {
      const state = getState();
      const { form: f } = state
      let form = f ?? {};

      const callForm =
        !form?.formatted?.[id] ||
        form?.formatted?.[id]?.status === "initialized" ||
        !form?.edit?.[id] ||
        form?.edit?.[id]?.status === "initialized";

      dispatch(
        generateAction(INDIVIDUAL_FORM_FORMATTED, {
          id,
          status: "loading",
        })
      );
      dispatch(
        generateAction(INDIVIDUAL_FORM_EDITABLE, {
          id,
          status: "loading",
        })
      );

      if (!Object.keys(form?.[id] || {}).length && callForm) {
        form[id] = await dispatch(getForm(rest.formType, id, osuid));
      }

      let specificForm = form[id];
      const formInfo = getFormByType(specificForm?.formType);

      let sections = formInfo?.sections ?? [];
      let { formattedSections, editForm } = await dispatch(
        iterateSections({ sections, state, form: specificForm })
      );

      dispatch(
        generateAction(INDIVIDUAL_FORM_FORMATTED, {
          id,
          responses: formattedSections,
        })
      );
      dispatch(
        generateAction(INDIVIDUAL_FORM_EDITABLE, {
          id,
          form: editForm,
        })
      );
    } catch (error) {
      console.error(error)
      dispatch(
        generateAction(INDIVIDUAL_FORM_FORMATTED, {
          id,
          status: "error",
        })
      );
      dispatch(
        generateAction(INDIVIDUAL_FORM_EDITABLE, {
          id,
          status: "error",
        })
      );
    }
  };
}

function _dispatchHistory(id, history) {
  return dispatch => {
    if (history) {
      const columnLabels = [
        {
          dataField: "date",
          label: "Date",
          width: "25%",
        },
        {
          dataField: "name",
          label: "Name",
          width: "20%",
        },
        {
          dataField: "action",
          label: "Action",
          width: "15%",
        },
        {
          dataField: "description",
          label: "Description",
          width: "25%",
        },
        {
          dataField: "taskActions",
          label: "Task Actions",
          width: "15%"
        }
      ]
  
      dispatch(
        generateAction(INDIVIDUAL_FORM_HISTORY, {
          history: history,
          columns: columnLabels,
          id,
        })
      );
    }
  }
}

export function getHistory({ emplid, formType, id }) {
  return async (dispatch, getState) => {
    let response
    try {
        dispatch(
          generateAction(INDIVIDUAL_FORM_HISTORY, {
            status: "loading",
            id,
          })
        );
        const { authentication } = getState();
        response = await API.graphql({
          query: Queries.getFormHistory,
          variables: {
            emplid: (emplid ?? authentication?.user?.osuid),
            formType,
            formId: id
          },
        });
        if (response?.data?.getFormHistory?.history) {
          dispatch(_dispatchHistory(id, response.data.getFormHistory.history))
        } else {
          throw new Error(`Unable to parse response from get forms`, response);
        }
    } catch (error) {
      console.error(error);
      dispatch(
        generateAction(INDIVIDUAL_FORM_HISTORY, {
          status: "error",
          id,
        })
      );
    }
  };
}

// updates form detail fields to include the label and display value
export function updateFormDetailFields(forms = []) {
  return async (dispatch) => {
    const arrayIndex = 0;
    for(const form of forms) {
      const formDefinition = getFormByType(form.formType);
      if(formDefinition) {
        const sections = (formDefinition?.sections ?? []);
        let questions = compact(flatten(sections.map(section => (section.questions))));
        if(Array.isArray(formDefinition.formDetailQuestions)) { // questions for formDetailFields without an associated section
          questions = questions.concat(formDefinition.formDetailQuestions);
        }
        const formDetailFields = (formDefinition?.formDetailFields ?? []);
        for(const formDetailField of formDetailFields) {
          const tokens = formDetailField.split(".");
          const field = tokens[0];
          const nestedField = (tokens.length > 1 ? tokens[1] : null);
          for(const question of questions) {
            let questionValue = form[question?.dataField];
            if(Array.isArray(questionValue)) questionValue = questionValue[arrayIndex];
            if(isDate(questionValue)) questionValue = formatDate(questionValue);
            if(question.dataField && question.dataField === field && question.title && questionValue) {
              if(nestedField) {
                if(question.questions) {
                  for(const nestedQuestion of question.questions) {
                    if(nestedQuestion.dataField && nestedQuestion.dataField === nestedField && nestedQuestion.title) {
                      const value = await dispatch(updateValue({ form, value: questionValue?.[nestedQuestion.dataField], question: nestedQuestion }));
                      const lvp = { label: nestedQuestion.title, value: (value?.title ?? value)};
                      if(Array.isArray(form[question.dataField])) {
                        form[question.dataField][arrayIndex][nestedQuestion.dataField] = lvp;
                      } else {
                        form[question.dataField][nestedQuestion.dataField] = lvp;
                      }
                    }
                  }
                }
              } else if(question.type === inputMappings.datetime && typeof questionValue === "object") {
                form[question.dataField] = { label: question.title, value: formatDate(questionValue.date, "MM-DD-YYYY") };
              } else {
                let value = await dispatch(updateValue({ form, value: questionValue, question }));
                if(value === BOOL.YES) value = "Yes";
                if(value === BOOL.NO) value = "No";
                form[question.dataField] = { label: question.title, value: (value?.title ?? value)};
              }
            }
            if(question.results) {
              for(const result of question.results) {
                if(result.dataField && result.dataField === field && result.label) {
                  form[result.dataField] = { label: result.label, value: form[result.dataField] };
                  break;
                }
              }
            }
            if(question.choices) {
              let hasMatchingChoice = false;
              for(const choice of question.choices) {
                if(choice.dataField && choice.dataField === field && choice.title) {
                  const choiceValue = await dispatch(updateValue({ form, value: form[choice.dataField], question: choice }));
                  let value = (choiceValue?.title ?? choiceValue?.value ?? choiceValue);
                  if(value === BOOL.YES) value = "Yes";
                  if(value === BOOL.NO) value = "No";
                  form[choice.dataField] = { label: choice.title, value };
                  hasMatchingChoice = true;
                  break;
                }
              }
              if(!hasMatchingChoice && question.type === inputMappings.checkbox && questionValue === BOOL.NO && question.dataField === field) {
                const title = (question?.title ?? question.choices[0]?.title);
                if(title) form[question.dataField] = { label: title, value: "No" };
              }
            }
          }
        }
      }
    }
  }
}

export function getLandingForms(filter = {}) {
  return async (dispatch) => {
      try {
          dispatch(buildAction(LANDING_FORMS, { status: ACTION_STATUS.LOADING }));
          const response = await API.graphql({ query: Queries.getForms, variables: { filter } });
          const errors = (response?.errors ?? []);
          if(errors.length > 0) throw new Error(errors[0].message);
          const forms = (response?.data?.getForms?.forms ?? []);
          await dispatch(updateFormDetailFields(forms));
          dispatch(buildAction(LANDING_FORMS, { status: ACTION_STATUS.SUCCESS, data: transformLandingForms(forms) }));
      } catch(error) {
          console.error("Get Landing Forms Error: ", error);
          dispatch(buildAction(LANDING_FORMS, { status: ACTION_STATUS.ERROR }));
      }
  };
}

export function approveForm(values, external = false, approvalForm) {
  return async (dispatch, getState) => {
    try { 
      const externalKeys = await getState()?.authentication?.user?.externalKeys;
      dispatch(buildAction(FORM_ACTION, { status: ACTION_STATUS.LOADING }));
      if(!isEmpty(approvalForm)) {
        const { emplid, formType, formId } = values;
        await API.graphql({ query: Mutations.updateApprovalForm, variables: { emplid, formType, formId, approvalForm } });
      }
      const params = { 
        query: Mutations.approveForm, 
        variables: { ...values } 
      }
      if(external && externalKeys){
        params.authMode = AWS_LAMBDA;
        params.authToken = `${externalKeys.ukey}:${externalKeys.akey}`
      }
      await API.graphql(params);
      dispatch(buildAction(FORM_ACTION, { status: ACTION_STATUS.SUCCESS, path: "/tasks" }));
    } catch (error) {
      console.error("Approve Form Error: ", error);
      let message = null;
      const { errors = [] } = error;
      if(errors.length > 0) {
        const errorType = errors[0].errorType;
        if(errorType === ERROR_TYPE.UNAUTHORIZED) message = "You lack sufficient security permissions to approve the requested form.";
        if(errorType === ERROR_TYPE.VALIDATION) message = errors[0].message;
      }
      dispatch(buildAction(FORM_ACTION, { status: ACTION_STATUS.ERROR, message }));
    }
  }
}

export function denyForm(values, external = false) {
  return async (dispatch, getState) => {
    try { 
      const externalKeys = await getState()?.authentication?.user?.externalKeys;
      dispatch(buildAction(FORM_ACTION, { status: ACTION_STATUS.LOADING }));
      const params = { 
        query: Mutations.denyForm, 
        variables: { ...values } 
      }
      if(external && externalKeys){
        params.authMode = AWS_LAMBDA;
        params.authToken = `${externalKeys.ukey}:${externalKeys.akey}`
      }
      await API.graphql(params);
      dispatch(buildAction(FORM_ACTION, { status: ACTION_STATUS.SUCCESS, path: "/tasks" }));
    } catch (error) {
      console.error("Deny Form Error: ", error);
      let message = null;
      const { errors = [] } = error;
      if(errors.length > 0) {
        const errorType = errors[0].errorType;
        if(errorType === ERROR_TYPE.UNAUTHORIZED) message = "You lack sufficient security permissions to deny the requested form.";
      }
      dispatch(buildAction(FORM_ACTION, { status: ACTION_STATUS.ERROR, message }));
    }
  }
}

export function cancelForm(values) {
  return async dispatch => {
    try { 
      dispatch(buildAction(FORM_ACTION, { status: ACTION_STATUS.LOADING }));
      await API.graphql({ query: Mutations.cancelForm, variables: { ...values } });
      dispatch(buildAction(FORM_ACTION, { status: ACTION_STATUS.SUCCESS }));
    } catch (error) {
      console.error("Cancel Form Error: ", error);
      let message = null;
      const { errors = [] } = error;
      if(errors.length > 0) {
        const errorType = errors[0].errorType;
        if(errorType === ERROR_TYPE.UNAUTHORIZED) message = "You lack sufficient security permissions to cancel the requested form.";
      }
      dispatch(buildAction(FORM_ACTION, { status: ACTION_STATUS.ERROR, message }));
    }
  }
}

export function updateApprovalForm(emplid, formType, formId, approvalForm) {
  return async (dispatch) => {
    try {
      dispatch(buildAction(FORM_ACTION, { status: ACTION_STATUS.LOADING }));
      await API.graphql({ query: Mutations.updateApprovalForm, variables: { emplid, formType, formId, approvalForm } });
      dispatch(buildAction(FORM_ACTION, { status: ACTION_STATUS.SUCCESS }));
    } catch(error) {
      console.error("Update Approval Form Error: ", error);
      let message = null;
      const { errors = [] } = error;
      if(errors.length > 0) {
        const errorType = errors[0].errorType;
        if(errorType === ERROR_TYPE.UNAUTHORIZED) message = "You lack sufficient security permissions to update the requested form.";
        if(errorType === ERROR_TYPE.VALIDATION) message = errors[0].message;
      }
      dispatch(buildAction(FORM_ACTION, { status: ACTION_STATUS.ERROR, message }));
    }
  }
}

export function completeTask(values) {
  return async dispatch => {
    try {
      dispatch(buildAction(TASK_ACTION, { status: ACTION_STATUS.LOADING }));
      await API.graphql({ query: Mutations.approveForm, variables: { ...values } });
      dispatch(buildAction(TASK_ACTION, { status: ACTION_STATUS.SUCCESS }));
    } catch (error) {
      console.error("Complete Task Error: ", error);
      dispatch(buildAction(TASK_ACTION, { status: ACTION_STATUS.ERROR }));
    }
  }
}

export function getTabbedData({ formType, osuid, id, ...rest }, external = false) {
  return async (dispatch, getState) => {
    dispatch(buildAction(STUDENT_INFORMATION, { status: ACTION_STATUS.LOADING }));
    dispatch(buildAction(INDIVIDUAL_FORM, { status: ACTION_STATUS.LOADING }));
    dispatch(
      generateAction(INDIVIDUAL_FORM_HISTORY, {
        status: "loading",
        id,
      })
    );
    const { authentication } = getState()
    const user = authentication?.user
    const loggedInId = user.osuid
    
    try { 
      let variables = {
        formId: id,
        formType
      }

      if(osuid) {
        variables.studentid = osuid
        variables.emplid = osuid
      } else {
        variables.studentid = loggedInId
        variables.emplid = loggedInId
      }

      const query = Custom.createTabbedDataQuery(formType)
      const params = {
        query,
        variables
      }
      const externalKeys = user?.externalKeys;
      if(external && externalKeys){
        params.authMode = AWS_LAMBDA;
        params.authToken = `${externalKeys.ukey}:${externalKeys.akey}`
      }
      const response = await API.graphql(params);
      
       dispatch(_getTabbedData(response, id))
    } catch (error) { 
      const caughtResponse = error?.data
      dispatch(_getTabbedData(caughtResponse, id))
      console.error(error)
    }
  }
}

const _getTabbedData = (response, id) => {
  return dispatch => {
    dispatch(_handleStudentInfo(response?.data?.getStudentInfo))
    dispatch(_handleGetForm(response?.data?.getForm, id))
    dispatch(_handleHistory(response?.data?.getFormHistory, id))
  }
}

const _handleStudentInfo = (getStudentInfo) =>  {
  return dispatch => {
    if(getStudentInfo?.record?.osuid) {
      dispatch(buildAction(STUDENT_INFORMATION, getStudentInfo?.record));
    } else {
      dispatch(buildAction(STUDENT_INFORMATION, { status: ACTION_STATUS.ERROR }));
    } 
  }
}

const _handleGetForm = (getForm, id) => {
  return dispatch => {
    if(getForm?.form?.id === id) {
      dispatch(generateAction(INDIVIDUAL_FORM, getForm?.form));
    } else {
      dispatch(buildAction(INDIVIDUAL_FORM, { status: ACTION_STATUS.ERROR }));

    }
  }
}

const _handleHistory = (getFormHistory, id) => {
  return dispatch => {
    if (getFormHistory?.history) {
      dispatch(_dispatchHistory(id, getFormHistory.history))
    } else {
      dispatch(generateAction(INDIVIDUAL_FORM_HISTORY, {
        status: ACTION_STATUS.ERROR,
        id,
      }))
    }
  }
}

