import { Mui } from "@osu/react-ui";
import React from "react";
import { dropdownSubtypes, inputMappings } from "../../util/enums";
import { FORM_TYPE, formTypeIdentifiers } from "../../util/constants";

export const formSections = [
  {
    id: "form",
    summary:
      "If any member of your committee has retired or left the university, for any reason, completion of the Committee and Examination Petition is required. Use the Continuing Graduate Committee Service Request option. Only one member of your committee can be so authorized.",
    questions: [
      {
        id: 'committe-exam-petition-option',
        type: inputMappings.radio,
        title: 'Please select the option that pertains to your request.',
        summary: "If the request is related to an examination, this form must be submitted to the Graduate School at least two weeks before the exam date.",
        dataField: "reasonForPetition",
        choices: [
          {
            id: 'one-sem-extension',
            value: "SEMESTER_EXTENSION",
            title: 'A one semester extension of the candidacy period.'
          },
          {
            id: 'waiver-of-rule',
            title: 'A waiver of the following rule in the Graduate School Handbook.',
            value: 'RULE_WAIVER',
            branch: 'grad-rule'
          },
          {
            id: 'grad-rule',
            title: 'Graduate School Handbook Waiver Rule',
            type: inputMappings.textInput,
            required: true,
            dataField: "ruleWaiverRuleId",
            dependencies: [
              'committe-exam-petition-option'
            ]
          }, 
          {
            id: 'non-member-permission',
            title: "Permission to include a non-member of the OSU graduate faculty on an exam committee. This person will be in addition to the required current Graduate Faculty members needed for a valid committee. In the Reasons section, list the committee member's name and reason for service.",
            value: "NON_OSU_GRADUATE_FACULTY",
            summary: "Requires a copy of person's CV with the petition. Add Atachment to this form.  If this person is a retired or former member of the OSU graduate faculty, and was a member of your post-candidacy doctoral committee or master's committee prior to their retirement or departure, please use the Continuing Graduate Committee Request option. (Applies only to PHD/DMA Final Defense exams and Master's exams)."
          },
          {
            id: 'continuing-grad-committee-request-option',
            title: "Continuing Graduate Committee Request option to have a former or retired member of the OSU graduate faculty continue to serve on the Ph.D. defense or master's exam committee and count towards the required faculty membership as: 1) The retired or former faculty member’s name and reason for inclusion on the committee.",
            value: 'FORMER_OSU_GRADUATE_FACULTY',
            branch: 'continuing-grad-committee-request-option-member-type'
          },
          {
            id: 'continuing-grad-committee-request-option-member-type',
            title: 'Member Type',
            dependencies: [
              'committe-exam-petition-option'
            ],
            type: inputMappings.radio,
            dataField: "formerCommMemberServingAs",
            summary: <React.Fragment>
              <Mui.Typography> In the Reasons section, list</Mui.Typography>
              <ol className="margin-y-1">
                <li>The retired or former faculty member's name.</li>
                <li>If request is for co-advisor status, a co-advisor must also be listed that is a current OSU Graduate Faculty member..
        Only one member of your committee can be authorized under this option.</li>
              </ol>
            </React.Fragment>,
            choices: [
              {
                id: 'co-advisor',
                value: 'COADVISOR',
                title: 'Co-Advisor'
              },
              {
                id: 'committee-member',
                value: 'COMMITTEE_MEMBER',
                title: 'Committee Member'
              }
            ]
          },
          {
            id: 'video-conf',
            title: "Permission to video conference my examination.",
            value: 'VIDEO_CONFERENCE',
            summary: <Mui.Box className="margin-left-4">
              <Mui.Typography>List required information in Reason section.</Mui.Typography>
              <ol className="margin-y-1">
                <li>Why a conference is necessary.</li>
                <li>Committee member who will be video conferenced.</li>
                <li>Location of member who will be video conferenced.</li>
                <li>Video conference method, i.e. Skype, WebEx, in-house video conference facility, etc.</li>
              </ol>
            </Mui.Box>,
          },
          {
            id: 'other-reason',
            value: 'OTHER',
            title: 'Other (Use Reason section below; be specific).'
          }
        ]
      },
      {
        id: 'reason-for-petition',
        title: "Reason for Petition",
        type: inputMappings.textInput,
        multiline: true,
        dataField: "reasonForPetitionComment",
        minRows: 5,
        summary: 'Reason for Petition required for all requests. Add attachment below if necessary. Information in this request is viewable and subject to disclosure under the Ohio public records statute and rules of discovery.'
      },
      {
        id: "file-uploder",
        type: "fileUpload",
        title: "Upload Attachments",
        accept: "application/pdf",
        dataField: "attachments",
        summary:
          "If this nomination is for Category P status please attach a letter of request from the Graduate Studies Committee and a current C.V. for the nominee.",
        required: false,
      },
      {
        title: 'Application Type',
        id: 'application-type',
        required: true,
        type: inputMappings.dropDown,
        dataField: "applicationType",
        choices: [
          {
            id: formTypeIdentifiers.APPLICATION_FOR_CANDIDACY_EXAM,
            value: formTypeIdentifiers.APPLICATION_FOR_CANDIDACY_EXAM,
            title: FORM_TYPE[formTypeIdentifiers.APPLICATION_FOR_CANDIDACY_EXAM]
          },
          {
            id: formTypeIdentifiers.APPLICATION_FOR_FINAL_EXAM,
            value: formTypeIdentifiers.APPLICATION_FOR_FINAL_EXAM,
            title: FORM_TYPE[formTypeIdentifiers.APPLICATION_FOR_FINAL_EXAM]
          },
          {
            id: formTypeIdentifiers.APPLICATION_TO_GRADUATE,
            value: formTypeIdentifiers.APPLICATION_TO_GRADUATE,
            title: FORM_TYPE[formTypeIdentifiers.APPLICATION_TO_GRADUATE]
          },
          {
            id: formTypeIdentifiers.APPLICATION_FOR_PROFESSIONAL_EXAM,
            value: formTypeIdentifiers.APPLICATION_FOR_PROFESSIONAL_EXAM,
            title: FORM_TYPE[formTypeIdentifiers.APPLICATION_FOR_PROFESSIONAL_EXAM]
          }
        ]
      },
      {
        title: "Graduation Level",
        id: "graduation-information-level",
        required: true,
        type: "dropDown",
        dataField: "gradLevel",
        summary:
          "If you are uncertain of your academic level, please contact your graduate program coordinator.",
        subtype: dropdownSubtypes.graduationLevel,
        branch: "graduation-information-plan",
      },
      {
        title: "Graduation Plan",
        id: "graduation-information-plan",
        dataField: "academicPlan",
        required: true,
        type: "dropDown",
        subtype: dropdownSubtypes.graduatePrograms,
        filter: [
          {
            field: "graduation-information-level",
            match: "gradLevel",
          },
        ],
        results: [
          {
            label: "Graduation Degree",
            field: "degree",
            dataField: "gradDegree",
          },
        ],
        branch: 'advisor-info',
        dependencyHelperText:
          "Please select a level to select the appropriate plan.",
        dependencies: ["graduation-information-level"],
      },
      {
        id: "advisor-info",
        type: "dropDown",
        title: "Advisor",
        subtype: dropdownSubtypes.advisor,
        dataField: "advisor",
        dependencies: [
          "graduation-information-plan"
        ],
        dependencyHelperText: "Please select a plan to select the appropriate advisor.",
        required: true
      },
    ],
  },
];

export const additionalFilters = [ {
  id: "submitted-date-after",
  title: "Submitted Date After",
  type: inputMappings.date,
  startTime: false,
  endTime: false,
},
{
  id: "submitted-date-before",
  title: "Submitted Date Before",
  type: inputMappings.date,
  startTime: false,
  endTime: false,
},
{
  id: "exam-reason",
  title: "Committee and Examination Reason",
  required: false,
  type: inputMappings.dropDown,
  subtype: dropdownSubtypes.examReason
}];
