import { CATEGORY_P } from "../../../util/constants";
import { dropdownSubtypes, inputMappings } from "../../../util/enums";

export const createCommitteeSection = (advisorDependencies) => {
  return {
    title: "Committee",
    id: "committee",
    questions: [
      {
        id: "advisor-subheading",
        type: inputMappings.subheading,
        title: "Advisor Information"
      },
      {
        id: "committee-advisor",
        type: inputMappings.dropDown,
        title: "Advisor",
        subtype: dropdownSubtypes.advisor,
        summary: null,
        required: true,
        numberOfResponsesMinimum: 1,
        numberOfResponsesMaximum: 1,
        dependencies: advisorDependencies || ["graduation-information-plan"],
        dependencyHelperText: "Please select a plan to select the appropriate advisor.",
        choices: [],
        dataField: "committee.advisor",
        filterState: {
          category: {
            dataField: "category",
            value: {
              category: CATEGORY_P
            }
          }
        }
      },
      {
        id: "co-advisor",
        type: inputMappings.dropDown,
        title: "Co-Advisor (if applicable)",
        subtype: dropdownSubtypes.coAdvisor,
        summary: null,
        required: false,
        dataField: "committee.coAdvisor"
      },
      {
        id: "committee-members",
        type: inputMappings.inputGroup,
        title: "Committee Members",
        subtitle: "Graduate Faculty committee members",
        summary: "(M)aster’s level faculty selection will require approval of the Graduate Studies Committee Chair.",
        required: true,
        numberOfResponsesMinimum: 1,
        numberOfResponsesMaximum: 4,
        dependencies: [],
        choices: [],
        dataField: "committee.members",
        questions: [
          {
            id: "committee-member-info",
            type: inputMappings.dropDown,
            subtype: dropdownSubtypes.facultyMember,
            title: "Faculty Member",
            summary: null,
            required: true
          }
        ]
      },
      {
        id: "external-members",
        type: inputMappings.inputGroup,
        title: "External Members",
        subtitle: "Non-Graduate Faculty committee members",
        summary: "For each external committee member, completion & submission of Committee and Examination Petition is required.",
        required: false,
        numberOfResponsesMinimum: 1,
        numberOfResponsesMaximum: 4,
        dependencies: [],
        choices: [],
        dataField: "committee.externalMembers",
        questions: [
          {
            id: "first-name",
            type: inputMappings.textInput,
            title: "First Name",
            dataField: 'firstName',
            summary: null,
            required: true,
            numberOfResponsesMinimum: 1,
            numberOfResponsesMaximum: 1,
            dependencies: [],
            choices: []
          },
          {
            id: "last-name",
            type: inputMappings.textInput,
            title: "Last Name",
            summary: null,
            required: true,
            dataField: 'lastName',
            numberOfResponsesMinimum: 1,
            numberOfResponsesMaximum: 1,
            dependencies: [],
            choices: []
          },
          {
            id: "email",
            type: inputMappings.email,
            title: "Email Address",
            dataField: "email",
            summary: null,
            required: true,
            numberOfResponsesMinimum: 1,
            numberOfResponsesMaximum: 1,
            dependencies: [],
            choices: []
          }
        ]
      }
    ]
  };
};