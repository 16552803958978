import { Mui } from "@osu/react-ui";
import React from "react";
import { BOOL } from "../../../util/constants";
import { isObject } from "../../../util/functions";

const pullValue = (value) => (isObject(value) && Object.prototype.hasOwnProperty.call((value || {}), 'value'))
  ? value.value
  : value

const Choices = (props = {}) => {
  const { choices, value: currentValue, idPrefix, onChange, error, ...rest } = props;
  const formattedValue = pullValue(currentValue)
  return choices.map((choice, index) => {
    const { value, title } = choice;
    let val = pullValue(value)

    return (
      <Mui.FormControlLabel
        key={`${idPrefix}${val}${index}`}
        label={<Mui.Typography color={(!!error && "error")}>{title}</Mui.Typography>}
        control={
          <Mui.Checkbox
            name={idPrefix + val}
            checked={formattedValue === val}
            onChange={(event) => {
              const checked = event?.target?.checked;
              const newValue = (checked === true ? value : BOOL.NO);
              onChange(newValue)
            }}
          />
        }
        {...rest}
      />
    );
  });
};

function Checkbox(props = {}) {
  const { value, onChange, id, label, choices, disabled, className, description, error, dataField, required, ...rest } = props;

  const choicesProps = {
    choices,
    value,
    idPrefix: id,
    onChange,
    disabled
  };
  
  if(!choices?.length) {
    choicesProps.choices = [{
      value: BOOL.YES,
      title: label
    }]
  }

  const singleCheckboxWithNoLabel = (choicesProps.choices.length === 1 & !label);
  if(singleCheckboxWithNoLabel) { // pass validation props to the single checkbox
    choicesProps.error = error;
    choicesProps.required = required;
  }

  return (
    <Mui.FormControl {...rest} required={required} error={!!error} component="fieldset" variant="standard" className={className}>
      {!singleCheckboxWithNoLabel && <Mui.FormLabel component="legend">{label}</Mui.FormLabel>}
      <Mui.FormGroup>
        <Choices {...choicesProps} />
      </Mui.FormGroup>
      {error && <Mui.FormHelperText error={true}>{error}</Mui.FormHelperText>}
      {description && <Mui.FormHelperText error={false}>{description}</Mui.FormHelperText>}
    </Mui.FormControl>
  );
}

export default Checkbox;
