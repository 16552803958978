import React, { useState } from "react";
import { FileUploader, Mui, MuiStyles } from "@osu/react-ui";

function FileUpload(props = {}) {
  const { onChange, id, readOnly, error, value, label, required, description, ...rest } = props;
  const [progress, setProgress] = useState(0)
  const theme = MuiStyles.useTheme();

  return (
    <Mui.FormControl required={required} error={error} {...rest}>
      <Mui.FormGroup id={id}>
        {label && <Mui.FormLabel sx={{ "&": { color: theme.palette.text.primary } }}>{label}</Mui.FormLabel>}
        {!!error && <Mui.FormHelperText error={true}>{error}</Mui.FormHelperText>}
        {description && <Mui.Typography color="secondary" variant="body2">{description}</Mui.Typography>}
        <FileUploader
          disabled={readOnly}
          onFileClick={(values) => console.log('onFileClick', values)}
          defaultValue={value}
          id={id}
          preventOutOfBounds
          onFileUpload={(file) => {
            onChange({
                id,
                value: file
            })
            setProgress(100)
        }}
          progress={progress}
          setProgress={setProgress}
          onDelete={(values) => console.log('onDelete', values)}
          helperTextProps={{
            variant: 'body2',
            className: 'margin-y-1'
          }}
          {...rest}
        />
      </Mui.FormGroup>
    </Mui.FormControl>
  );
}

export default FileUpload;
