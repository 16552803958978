import { BOOL, GPA_MAX_LENGTH } from "../../util/constants";
import { dropdownSubtypes, inputMappings } from "../../util/enums";

export const formSections = [
  {
    id: "combined-degree-program",
    questions: [
      {
        title: "Current Career",
        id: "career-type",
        required: true,
        type: inputMappings.dropDown,
        dataField: "currentCareer",
        subtype: dropdownSubtypes.careerType
      },
      {
        title: "Proposed Graduation Plan",
        id: "graduation-information-plan",
        required: true,
        type: inputMappings.dropDown,
        dataField: "academicPlan",
        subtype: dropdownSubtypes.graduatePrograms
      },
      {
        id: "app-submitted-radio",
        type: inputMappings.checkbox,
        defaultValue: BOOL.NO,
        dataField: "isApplicationSubmitted",
        choices: [
          {
            id: "app-submitted-grad-admissions",
            title: "Application Submitted to Graduate Admissions?",
            value: BOOL.YES
          }
        ]
      },
      {
        title: "Matriculation Term",
        id: "matriculation-term",
        required: true,
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.term,
        dataField: "termCode",
        filter: {
          showFromPresent: 5
        }
      },
      {
        title: "CUM Undergrad GPA",
        id: "cum-undergrad-gpa",
        required: true,
        dataField: "cumUndergradGPA",
        type: inputMappings.numberInput,
        inputProps: {
          max: 4,
          maxLength: GPA_MAX_LENGTH,
          min: 0,
          onInput: (event) => { // enforce maxLength
            const value = event.target.value;
            const maxLength = GPA_MAX_LENGTH; // ensures maxLength cannot be manipulated on the UI
            if(value.length > maxLength) {
              event.target.value = value.slice(0, maxLength);
            }
          },
          step: 0.1
        }
      },
      {
        id: "courses-input-group",
        title: "Courses",
        dataField: "courses",
        type: inputMappings.inputGroup,
        numberOfResponsesMinimum: 1,
        numberOfResponsesMaximum: 10,
        questions: [
          {
            id: "courses-ig-subject-area",
            type: inputMappings.textInput,
            title: "Department",
            summary: null,
            required: true,
            dataField: "department",
            gridProps: {
              xs: 12,
              sm: 6,
              md: 3
            }
          },
          {
            id: "courses-ig-term",
            type: inputMappings.dropDown,
            subtype: dropdownSubtypes.term,
            dataField: "termCode",
            title: "Term",
            summary: null,
            required: true,
            gridProps: {
              xs: 12,
              sm: 6,
              md: 3
            },
            filter: {
              showFromPresent: 8
            }
          },
          {
            id: "courses-ig-number",
            type: inputMappings.numberInput,
            title: "Course Number",
            dataField: "courseNumber",
            summary: null,
            required: true,
            gridProps: {
              xs: 12,
              sm: 6,
              md: 3
            },
            inputProps: {
              min: 0
            }
          },
          {
            id: "courses-ig-credit-hours",
            type: inputMappings.numberInput,
            title: "Credit Hours",
            dataField: "creditHours",
            summary: null,
            required: true,
            gridProps: {
              xs: 12,
              sm: 6,
              md: 3
            },
            inputProps: {
              min: 0
            }
          }
        ]
      },
      {
        id: "committee-approvers",
        type: inputMappings.inputGroup,
        title: "Approvers",
        subtitle: "OSU Employees",
        required: false,
        hidden: true,
        hiddenResponse: true,
        numberOfResponsesMinimum: 1,
        numberOfResponsesMaximum: 5,
        dataField: "committee.approvers",
        deleteAll: true,
        questions: [
          {
            id: "approver-info",
            type: inputMappings.nameNLookup,
            title: "OSU Employee"
          }
        ]
      }
    ]
  }
];

export const gradSchoolPreApprovalQuestions = [
  {
    id: "committee-approvers",
    type: inputMappings.inputGroup,
    title: "Approvers",
    subtitle: "OSU Employees",
    required: true,
    numberOfResponsesMinimum: 1,
    numberOfResponsesMaximum: 5,
    dataField: "committee.approvers",
    deleteAll: true,
    questions: [
      {
        id: "approver-info",
        type: inputMappings.nameNLookup,
        title: "OSU Employee"
      }
    ]
  }
];

export const additionalFilters = [
  {
    id: "proposed-graduation-plan",
    title: "Proposed Graduate Plans",
    required: false,
    type: inputMappings.dropDown,
    subtype: dropdownSubtypes.graduatePrograms
  }
];
