import { find } from "lodash";
import ApplicationForCandidacyExamForm from "../components/ApplicationForCandidacyExam.js";
import ApplicationForCandidacyExamApprovalForm from "../../Form/components/Approval/ApplicationForCandidacyExam.js";
import * as ApplicationForCandidacyExamData from "../data/ApplicationForCandidacyExam.js";
import * as ApplicationForCertificateCompletionData from "../data/ApplicationForCertificateCompletion.js";
import ApplicationForFinalExamForm from "../components/ApplicationForFinalExam.js";
import ApplicationForFinalExamApprovalForm from "../../Form/components/Approval/ApplicationForFinalExam.js";
import * as ApplicationForFinalExamData from "../data/ApplicationForFinalExam.js";
import ApplicationForProfessionalExamForm from "../components/ApplicationForProfessionalExam.js";
import ApplicationForProfessionalExamApprovalForm from "../../Form/components/Approval/ApplicationForProfessionalExam.js";
import * as ApplicationForProfessionalExamData  from "../data/ApplicationForProfessionalExam.js";
import * as ApplicationToGraduateData from "../data/ApplicationToGraduate.js";
import CombinedDegreeProgramApprovalForm from "../../Form/components/Approval/CombinedDegreeProgram.js";
import * as CombinedDegreeProgramData from "../data/CombinedDegreeProgram.js";
import * as CommitteeAndExamPetitionData from "../data/CommitteeAndExamPetition.js";
import DelayOfFinalDocumentForm from "../components/DelayOfFinalDocument.js";
import * as DelayOfFinalDocumentData from "../data/DelayOfFinalDocument.js";
import * as DualDegreeProgramData from "../data/DualDegreeProgram.js";
import GraduateFacultyNominationForm from "../components/GraduateFacultyNomination.js";
import * as GraduateFacultyNominationData from "../data/GraduateFacultyNomination.js";
import * as LateCoursePetitionData from "../data/LateCoursePetition.js";
import LeaveOfAbsenceFromProgramForm from "../components/LeaveOfAbsenceFromProgram.js";
import * as LeaveOfAbsenceFromProgramData from "../data/LeaveOfAbsenceFromProgram.js"
import * as MinorsAndSpecializationsData from "../data/MinorsAndSpecializations.js"
import SpecializationForm from "../components/Specialization.js";
import * as SpecializationData from "../data/Specialization.js";
import TransferGraduateCreditForm from "../components/TransferGraduateCredit.js";
import * as TransferGraduateCreditData from "../data/TransferGraduateCredit.js";
import TransferGraduateCreditApprovalForm from "../../Form/components/Approval/TransferGraduateCredit.js";
import WithdrawalFromProgramForm from "../components/WithdrawalFromProgram.js";
import * as WithdrawalFromProgramData from "../data/WithdrawalFromProgram.js"
import { APPLICATION_ROLE, BOOL, formTypeIdentifiers, FORM_TYPE, FORM_TYPE_HTML_FRIENDLY } from "../../util/constants.js"
import { formData, inputMappings } from "../../util/enums";

export const formDefinitions = {
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.APPLICATION_FOR_CANDIDACY_EXAM]]: {
        title: FORM_TYPE[formTypeIdentifiers.APPLICATION_FOR_CANDIDACY_EXAM],
        form: ApplicationForCandidacyExamForm,
        sections: ApplicationForCandidacyExamData.formSections,
        approvalForm: ApplicationForCandidacyExamApprovalForm,
        formType: formTypeIdentifiers.APPLICATION_FOR_CANDIDACY_EXAM,
        adminRoles: [APPLICATION_ROLE.GRADUATION_ADMIN],
        additionalFilters: ApplicationForCandidacyExamData.additionalFilters,
        isStudentForm: true,
        canCreateForm: (find(formData["graduation-and-examination"].forms, ["name", FORM_TYPE[formTypeIdentifiers.APPLICATION_FOR_CANDIDACY_EXAM]])?.canCreate ?? false),
        formDetailFields: ["oralDate", "academicPlan", "candidacyType", "categoryMApproved"],
        formDetailQuestions: [{ id: "academic-plan", title: "Academic Plan", dataField: "academicPlan" }],
        useUserAcademicPlan: true
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.APPLICATION_FOR_CERTIFICATE_COMPLETION]]: {
        title: FORM_TYPE[formTypeIdentifiers.APPLICATION_FOR_CERTIFICATE_COMPLETION],
        sections: ApplicationForCertificateCompletionData.formSections,
        formType: formTypeIdentifiers.APPLICATION_FOR_CERTIFICATE_COMPLETION,
        adminRoles: [APPLICATION_ROLE.GRADUATION_ADMIN],
        additionalFilters: ApplicationForCertificateCompletionData.additionalFilters,
        isStudentForm: true,
        canCreateForm: (find(formData["graduation-and-examination"].forms, ["name", FORM_TYPE[formTypeIdentifiers.APPLICATION_FOR_CERTIFICATE_COMPLETION]])?.canCreate ?? false),
        formDetailFields: ["termCode", "academicPlan"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.APPLICATION_FOR_FINAL_EXAM]]: {
        title: FORM_TYPE[formTypeIdentifiers.APPLICATION_FOR_FINAL_EXAM],
        form: ApplicationForFinalExamForm,
        sections: ApplicationForFinalExamData.formSections,
        approvalForm: ApplicationForFinalExamApprovalForm,
        approvalText: "By clicking on approve, the members of the dissertation or D.M.A. committee judge that the Dissertation/D.M.A. document" +
            " draft of the student named above is of sufficient merit to warrant holding the Final Oral Examination.",
        formType: formTypeIdentifiers.APPLICATION_FOR_FINAL_EXAM,
        adminRoles: [APPLICATION_ROLE.GRADUATION_ADMIN],
        additionalFilters: ApplicationForFinalExamData.additionalFilters,
        isStudentForm: true,
        canCreateForm: (find(formData["graduation-and-examination"].forms, ["name", FORM_TYPE[formTypeIdentifiers.APPLICATION_FOR_FINAL_EXAM]])?.canCreate ?? false),
        formDetailFields: ["oralDate", "academicPlan", "finalExamType"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.APPLICATION_FOR_PROFESSIONAL_EXAM]]: {
        title: FORM_TYPE[formTypeIdentifiers.APPLICATION_FOR_PROFESSIONAL_EXAM],
        form: ApplicationForProfessionalExamForm,
        sections: ApplicationForProfessionalExamData.formSections,
        approvalForm: ApplicationForProfessionalExamApprovalForm,
        formType: formTypeIdentifiers.APPLICATION_FOR_PROFESSIONAL_EXAM,
        adminRoles: [APPLICATION_ROLE.GRADUATION_ADMIN],
        additionalFilters: ApplicationForProfessionalExamData.additionalFilters,
        isStudentForm: true,
        canCreateForm: (find(formData["graduation-and-examination"].forms, ["name", FORM_TYPE[formTypeIdentifiers.APPLICATION_FOR_PROFESSIONAL_EXAM]])?.canCreate ?? false),
        formDetailFields: ["oralDate", "academicPlan", "professionalExamType", "categoryMApproved"],
        formDetailQuestions: [{ id: "academic-plan", title: "Academic Plan", dataField: "academicPlan" }],
        useUserAcademicPlan: true
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.APPLICATION_TO_GRADUATE]]: {
        title: FORM_TYPE[formTypeIdentifiers.APPLICATION_TO_GRADUATE],
        sections: ApplicationToGraduateData.formSections,
        formType: formTypeIdentifiers.APPLICATION_TO_GRADUATE,
        adminRoles: [APPLICATION_ROLE.GRADUATION_ADMIN],
        additionalFilters: ApplicationToGraduateData.additionalFilters,
        isStudentForm: true,
        canCreateForm: (find(formData["graduation-and-examination"].forms, ["name", FORM_TYPE[formTypeIdentifiers.APPLICATION_TO_GRADUATE]])?.canCreate ?? false),
        formDetailFields: ["termCode", "gradLevel", "academicPlan", "gradDegree", "endOfSemesterOption"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.COMBINED_DEGREE_PROGRAM]]: {
        title: FORM_TYPE[formTypeIdentifiers.COMBINED_DEGREE_PROGRAM],
        sections: CombinedDegreeProgramData.formSections,
        approvalForm: CombinedDegreeProgramApprovalForm,
        type: FORM_TYPE.ENROLLMENT,
        formType: formTypeIdentifiers.COMBINED_DEGREE_PROGRAM,
        adminRoles: [APPLICATION_ROLE.ENROLLMENT_ADMIN],
        additionalFilters: CombinedDegreeProgramData.additionalFilters,
        isStudentForm: true,
        canCreateForm: (find(formData["enrollment"].forms, ["name", FORM_TYPE[formTypeIdentifiers.COMBINED_DEGREE_PROGRAM]])?.canCreate ?? false),
        formDetailFields: ["currentCareer", "academicPlan"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.COMMITTEE_AND_EXAM_PETITION]]: {
        title: FORM_TYPE[formTypeIdentifiers.COMMITTEE_AND_EXAM_PETITION],
        sections: CommitteeAndExamPetitionData.formSections,
        formType: formTypeIdentifiers.COMMITTEE_AND_EXAM_PETITION,
        adminRoles: [APPLICATION_ROLE.GRADUATION_ADMIN],
        additionalFilters: CommitteeAndExamPetitionData.additionalFilters,
        isStudentForm: true,
        canCreateForm: (find(formData["graduation-and-examination"].forms, ["name", FORM_TYPE[formTypeIdentifiers.COMMITTEE_AND_EXAM_PETITION]])?.canCreate ?? false),
        formDetailFields: ["academicPlan", "applicationType", "reasonForPetition"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.DELAY_OF_FINAL_DOCUMENT]]: {
        title: FORM_TYPE[formTypeIdentifiers.DELAY_OF_FINAL_DOCUMENT],
        form: DelayOfFinalDocumentForm,
        sections: DelayOfFinalDocumentData.formSections,
        formType: formTypeIdentifiers.DELAY_OF_FINAL_DOCUMENT,
        adminRoles: [APPLICATION_ROLE.GRADUATION_ADMIN],
        isStudentForm: true,
        canCreateForm: (find(formData["graduation-and-examination"].forms, ["name", FORM_TYPE[formTypeIdentifiers.DELAY_OF_FINAL_DOCUMENT]])?.canCreate ?? false),
        formDetailFields: ["gradLevel", "academicPlan", "gradDegree"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.DUAL_DEGREE_PROGRAM]]: {
        title: FORM_TYPE[formTypeIdentifiers.DUAL_DEGREE_PROGRAM],
        sections: DualDegreeProgramData.formSections,
        type: FORM_TYPE.ENROLLMENT,
        formType: formTypeIdentifiers.DUAL_DEGREE_PROGRAM,
        adminRoles: [APPLICATION_ROLE.ENROLLMENT_ADMIN],
        isStudentForm: true,
        canCreateForm: (find(formData["enrollment"].forms, ["name", FORM_TYPE[formTypeIdentifiers.DUAL_DEGREE_PROGRAM]])?.canCreate ?? false),
        formDetailFields: ["academicPlan"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.GRAD_FACULTY_NOMINATION]]: {
        title: FORM_TYPE[formTypeIdentifiers.GRAD_FACULTY_NOMINATION],
        form: GraduateFacultyNominationForm,
        sections: GraduateFacultyNominationData.formSections,
        formType: formTypeIdentifiers.GRAD_FACULTY_NOMINATION,
        adminRoles: [APPLICATION_ROLE.ENROLLMENT_ADMIN, APPLICATION_ROLE.FACULTY_APPROVER],
        isStudentForm: false,
        canCreateForm: true,
        contextFields: {
            studentid: "facultyMember.emplid"
        },
        pk: "facultyMember.emplid",
        formDetailFields: ["academicPlan", "proposedCategory", "proposedGradProgram"] // TODO: How to display Graduate Faculty first and last name together?
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.LATE_COURSE_PETITION]]: {
        title: FORM_TYPE[formTypeIdentifiers.LATE_COURSE_PETITION],
        sections: LateCoursePetitionData.formSections,
        type: FORM_TYPE.ENROLLMENT,
        formType: formTypeIdentifiers.LATE_COURSE_PETITION,
        adminRoles: [APPLICATION_ROLE.ENROLLMENT_ADMIN],
        isStudentForm: true,
        canCreateForm: (find(formData["enrollment"].forms, ["name", FORM_TYPE[formTypeIdentifiers.LATE_COURSE_PETITION]])?.canCreate ?? false),
        formDetailFields: ["termCode", "courses.action", "courses.courseSubject", "courses.courseNumber", "courses.classNumber", "courses.instructor"],
        useUserAcademicPlan: true
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.LEAVE_OF_ABSENCE_FROM_PROGRAM]]: {
        title: FORM_TYPE[formTypeIdentifiers.LEAVE_OF_ABSENCE_FROM_PROGRAM],
        form: LeaveOfAbsenceFromProgramForm,
        sections: LeaveOfAbsenceFromProgramData.formSections,
        formType: formTypeIdentifiers.LEAVE_OF_ABSENCE_FROM_PROGRAM,
        adminRoles: [APPLICATION_ROLE.ENROLLMENT_ADMIN],
        isStudentForm: true,
        canCreateForm: (find(formData["enrollment"].forms, ["name", FORM_TYPE[formTypeIdentifiers.LEAVE_OF_ABSENCE_FROM_PROGRAM]])?.canCreate ?? false),
        formDetailFields: ["pcdIndicator", "academicPlan", "fromTermCode", "toTermCode"],
        formDetailQuestions: [{
            id: "post-candidacy",
            dataField: "pcdIndicator",
            type: inputMappings.checkbox,
            defaultValue: BOOL.NO,
            choices: [{ id: "yes-post-candidacy", title: "Post-Candidacy", dataField: "pcdIndicator", value: BOOL.YES }]
        }]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.MINORS_AND_SPECIALIZATIONS]]: {
        title: FORM_TYPE[formTypeIdentifiers.MINORS_AND_SPECIALIZATIONS],
        sections: MinorsAndSpecializationsData.formSections,
        formType: formTypeIdentifiers.MINORS_AND_SPECIALIZATIONS,
        adminRoles: [APPLICATION_ROLE.ENROLLMENT_ADMIN],
        additionalFilters: MinorsAndSpecializationsData.additionalFilters,
        isStudentForm: true,
        canCreateForm: (find(formData["enrollment"].forms, ["name", FORM_TYPE[formTypeIdentifiers.MINORS_AND_SPECIALIZATIONS]])?.canCreate ?? false),
        formDetailFields: ["concentrationType", "designation"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.REPORT_ON_CANDIDACY]]: {
        title: FORM_TYPE[formTypeIdentifiers.REPORT_ON_CANDIDACY],
        formType: formTypeIdentifiers.REPORT_ON_CANDIDACY,
        adminRoles: [APPLICATION_ROLE.GRADUATION_ADMIN],
        isStudentForm: true,
        canCreateForm: (find(formData["reports"].forms, ["name", FORM_TYPE[formTypeIdentifiers.REPORT_ON_CANDIDACY]])?.canCreate ?? false),
        formDetailFields: ["oralDate", "beginDate", "endDate"] // TODO: How to show the begin and end date as time?
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.REPORT_ON_FINAL_DOCUMENT]]: {
        title: FORM_TYPE[formTypeIdentifiers.REPORT_ON_FINAL_DOCUMENT],
        formType: formTypeIdentifiers.REPORT_ON_FINAL_DOCUMENT,
        adminRoles: [APPLICATION_ROLE.GRADUATION_ADMIN],
        isStudentForm: true,
        canCreateForm: (find(formData["reports"].forms, ["name", FORM_TYPE[formTypeIdentifiers.REPORT_ON_FINAL_DOCUMENT]])?.canCreate ?? false),
        formDetailFields: ["oralDate", "academicPlan"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.REPORT_ON_FINAL_EXAM]]: {
        title: FORM_TYPE[formTypeIdentifiers.REPORT_ON_FINAL_EXAM],
        formType: formTypeIdentifiers.REPORT_ON_FINAL_EXAM,
        adminRoles: [APPLICATION_ROLE.GRADUATION_ADMIN],
        isStudentForm: true,
        canCreateForm: (find(formData["reports"].forms, ["name", FORM_TYPE[formTypeIdentifiers.REPORT_ON_FINAL_EXAM]])?.canCreate ?? false),
        formDetailFields: ["oralDate", "academicPlan"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.REPORT_ON_PROF_EXAM]]: {
        title: FORM_TYPE[formTypeIdentifiers.REPORT_ON_PROF_EXAM],
        formType: formTypeIdentifiers.REPORT_ON_PROF_EXAM,
        adminRoles: [APPLICATION_ROLE.GRADUATION_ADMIN],
        isStudentForm: true,
        canCreateForm: (find(formData["reports"].forms, ["name", FORM_TYPE[formTypeIdentifiers.REPORT_ON_PROF_EXAM]])?.canCreate ?? false),
        formDetailFields: ["beginDate", "professionalExamType"] // TODO: How to show begin date and time separately?
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.SPECIALIZATION]]: {
        title: FORM_TYPE[formTypeIdentifiers.SPECIALIZATION],
        sections: SpecializationData.formSections,
        formType: formTypeIdentifiers.SPECIALIZATION,
        adminRoles: [APPLICATION_ROLE.ENROLLMENT_ADMIN, APPLICATION_ROLE.GRADUATION_ADMIN],
        isStudentForm: false,
        canCreateForm: true,
        contextFields: {
            studentid: "osuid.emplid",
            name: "osuid.name"
        },
        formDetailFields: ["specialization"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.TRANSCRIPT_REQUEST]]: {
        title: FORM_TYPE[formTypeIdentifiers.TRANSCRIPT_REQUEST],
        formType: formTypeIdentifiers.TRANSCRIPT_REQUEST,
        adminRoles: [APPLICATION_ROLE.GRADUATION_ADMIN],
        isStudentForm: true,
        canCreateForm: false,
        formDetailFields: ["concentrationType", "designation"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.TRANSFER_GRADUATE_CREDIT]]: {
        title: FORM_TYPE[formTypeIdentifiers.TRANSFER_GRADUATE_CREDIT],
        form: TransferGraduateCreditForm,
        sections: TransferGraduateCreditData.formSections,
        approvalForm: TransferGraduateCreditApprovalForm,
        type: FORM_TYPE.ENROLLMENT,
        formType: formTypeIdentifiers.TRANSFER_GRADUATE_CREDIT,
        adminRoles: [APPLICATION_ROLE.ENROLLMENT_ADMIN],
        additionalFilters: TransferGraduateCreditData.additionalFilters,
        isStudentForm: true,
        canCreateForm: (find(formData["enrollment"].forms, ["name", FORM_TYPE[formTypeIdentifiers.TRANSFER_GRADUATE_CREDIT]])?.canCreate ?? false),
        formDetailFields: ["academicPlan"]
    },
    [FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.WITHDRAWAL_FROM_PROGRAM]]: {
        title: FORM_TYPE[formTypeIdentifiers.WITHDRAWAL_FROM_PROGRAM],
        form: WithdrawalFromProgramForm,
        sections: WithdrawalFromProgramData.formSections,
        type: FORM_TYPE.ENROLLMENT,
        formType: formTypeIdentifiers.WITHDRAWAL_FROM_PROGRAM,
        adminRoles: [APPLICATION_ROLE.ENROLLMENT_ADMIN],
        isStudentForm: true,
        canCreateForm: (find(formData["enrollment"].forms, ["name", FORM_TYPE[formTypeIdentifiers.WITHDRAWAL_FROM_PROGRAM]])?.canCreate ?? false),
        formDetailFields: ["academicPlan"]
    }
}

export const links = Object.keys(formDefinitions).map(id => {
    return {
        path: `/forms/${id}`,
        title: formDefinitions[id].title
    }
})

const getFormByType  = (type) => {
    const entries = Object.values(formDefinitions)
    const form = entries.find(form => form.formType === type)

    return form
}

export { 
    formDefinitions as default,
    getFormByType
}
