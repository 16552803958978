import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useFormSubmission from "../hooks/useFormSubmission";

export default function ApplicationForFinalExamination(props = {}) {
  const { Component, match = {} } = props;
  const { params = {}, pathname = "" } = match;
  const { formType: formTypeFriendly } = params;
  const isCreate = pathname.includes("/create");
  
  const user = useSelector((state) => (state?.authentication?.user ?? {}));
  const { academicPlan = null, hasProfessionalAcademicPlan = false } = user;
  const osuid = (params?.osuid ?? user.osuid);
  const { navigateToFormLandingPage } = useFormSubmission({ osuid });
  const [hasValidated, setValidated] = useState(false);
  const [isValidating, setValidating] = useState(true);

  const validateCreate = useCallback(async () => {
    if(!academicPlan) return navigateToFormLandingPage(formTypeFriendly, "Unable to find a valid academic plan. If you are a current student, please contact your graduate office.");
    if(hasProfessionalAcademicPlan) return navigateToFormLandingPage(formTypeFriendly, "This application is for the PhD, be sure this is the application you want to submit. Please contact your graduate office for any help.");
  }, [academicPlan, formTypeFriendly, hasProfessionalAcademicPlan, navigateToFormLandingPage]);

  useEffect(() => {
    if(isCreate && hasValidated === false && isValidating === true) {
      validateCreate();
      setValidated(true);
      setValidating(false);
    } else {
      setValidating(false);
    }
  }, [hasValidated, isCreate, isValidating, validateCreate]);

  return (<Component {...props} isValidating={isValidating} />);
}