import { Mui } from "@osu/react-ui";
import { Fragment } from "react";
import { BOOL, CATEGORY_P, PROFESSIONAL_EXAM_TYPE } from "../../util/constants";
import { dropdownSubtypes, inputMappings } from "../../util/enums";

export const formSections = [
    {
      id: "prof-exam-info",
      title: "Professional Examination Information",
      questions: [
        {
          id: "term",
          title: "Term",
          required: true,
          type: inputMappings.dropDown,
          subtype: dropdownSubtypes.term,
          dataField: "termCode",
          filter: {
            showFromPresent: 2
          }
        },
        {
          id: "professional-exam-type",
          title: "Professional Examination Type",
          required: true,
          type: inputMappings.dropDown,
          dataField: "professionalExamType",
          choices: [
            {
              id: "first-professional-exam",
              title: "First Professional Exam",
              value: PROFESSIONAL_EXAM_TYPE.FIRST_PROFESSIONAL_EXAM
            },
            {
              id: "second-professional-exam",
              title: "Second Professional Exam",
              value: PROFESSIONAL_EXAM_TYPE.SECOND_PROFESSIONAL_EXAM
            }
          ]
        },
        {
          id: "date-input-group",
          type: inputMappings.datetime,
          dataField: "examDate",
          startTime: true,
          endTime: false,
          summary: "The Graduate School must be notified of the proposed date, time and place of the examination at least two weeks in advance.",
          required: true,
          validation: {
            begin: "7:30 AM",
            days: 14,
            end: "3:30 PM",
            weekend: true
          }
        },
        {
          id: "video-conf-component",
          type: inputMappings.checkbox,
          dataField: "videoConference",
          defaultValue: BOOL.NO,
          choices: [
            {
              id: "yes-video-conf-component",
              value: BOOL.YES,
              title: "There will be a video conference component to this examination.",
              branch: "video-conf-platform"
            }
          ]
        },
        {
          id: "video-conf-platform",
          title: "Video Conference Platform (example: Zoom meeting, Microsoft Teams, Skype, etc.)",
          dependencies: ["video-conf-component"],
          dataField: "videoConferencePlatform",
          type: inputMappings.textInput,
          required: true,
          summary: (
            <Fragment>
              <Mui.Typography variant="body2" className="bold margin-bottom-1">
                For examinations that are entirely virtual you do not need to include room and building.
              </Mui.Typography>
              <Mui.Typography variant="body2">
                If your examination is both virtual and in person please include room and building.
              </Mui.Typography>
            </Fragment>
          ),
          inputProps: {
            maxLength: 255
          }
        },
        {
          id: "room-number-building",
          type: inputMappings.inputGroup,
          numberOfResponsesMaximum: 1,
          questions: [
            {
              id: "room-num",
              title: "Room Number",
              dataField: "roomNumber",
              type: inputMappings.textInput,
              inputProps: {
                maxLength: 255
              }
            },
            {
              id: "building",
              title: "Building",
              dataField: "building",
              type: inputMappings.textInput,
              inputProps: {
                maxLength: 255
              }
            }
          ]
        }
      ]
    },
    {
      id: "professional-examination-committee",
      title: "Professional Examination Committee",
      questions: [
        {
          id: "advisor-subheading",
          type: inputMappings.subheading,
          title: "Advisor Information"
        },
        {
          id: "committee-advisor",
          type: inputMappings.dropDown,
          title: "Advisor",
          dataField: "committee.advisor",
          summary: null,
          required: true,
          subtype: dropdownSubtypes.advisor,
          filterState: {
            category: {
              dataField: "category",
              value: {
                category: CATEGORY_P
              }
            }
          }
        },
        {
          id: "co-advisor",
          type: inputMappings.dropDown,
          title: "Co-Advisor (if applicable)",
          summary: null,
          required: false,
          dataField: "committee.coAdvisor",
          subtype: dropdownSubtypes.coAdvisor
        },
        {
          id: "committee-members",
          type: inputMappings.inputGroup,
          title: "Committee Members",
          subtitle: "Graduate Faculty committee members",
          summary: "(M)aster’s level faculty selection will require approval of the Graduate Studies Committee Chair.",
          required: true,
          numberOfResponsesMinimum: 1,
          numberOfResponsesMaximum: 4,
          dependencies: [],
          choices: [],
          dataField: "committee.members",
          questions: [
            {
              id: "committee-member-info",
              type: inputMappings.dropDown,
              title: "Faculty Member",
              subtype: dropdownSubtypes.facultyMember,
              summary: null,
              required: true
            }
          ]
        },
        {
          id: "external-members",
          type: inputMappings.inputGroup,
          title: "External Members",
          subtitle: "Non-Graduate Faculty committee members",
          summary: "For each external committee member, completion & submission of Committee and Examination Petition is required.",
          required: false,
          numberOfResponsesMinimum: 1,
          numberOfResponsesMaximum: 4,
          dependencies: [],
          choices: [],
          dataField: "committee.externalMembers",
          questions: [
            {
              id: "first-name",
              type: inputMappings.textInput,
              title: "First Name",
              summary: null,
              required: true,
              numberOfResponsesMinimum: 1,
              numberOfResponsesMaximum: 1,
              dataField: "firstName",
              dependencies: [],
              choices: []
            },
            {
              id: "last-name",
              type: inputMappings.textInput,
              title: "Last Name",
              summary: null,
              required: true,
              numberOfResponsesMinimum: 1,
              numberOfResponsesMaximum: 1,
              dataField: "lastName",
              dependencies: [],
              choices: []
            },
            {
              id: "email",
              type: inputMappings.email,
              title: "Email Address",
              summary: null,
              required: true,
              numberOfResponsesMinimum: 1,
              numberOfResponsesMaximum: 1,
              dataField: "email",
              dependencies: [],
              choices: []
            }
          ]
        },
        {
          id: "category-m-approved",
          type: inputMappings.checkbox,
          dataField: "categoryMApproved",
          defaultValue: BOOL.NO,
          hidden: true,
          choices: [
            {
              id: "yes-category-m-approved",
              title: "Category M Approved",
              value: BOOL.YES
            }
          ]
        }
      ]
    }
];

export const gradChairApprovalQuestions = [
  {
    id: "category-m-approved",
    type: inputMappings.checkbox,
    dataField: "categoryMApproved",
    choices: [
      {
        id: "category-m-approved-yes",
        title: "Category M faculty approved",
        value: BOOL.YES
      }
    ],
    validation: (form, value) => (value !== BOOL.YES ? "Category M Approval is required." : null)
  }
];

export const additionalFilters = [
  {
    id: "exam-date-after",
    title: "Exam Date After",
    type: inputMappings.date,
    startTime: false,
    endTime: false
  },
  {
    id: "exam-date-before",
    title: "Exam Date Before",
    type: inputMappings.date,
    startTime: false,
    endTime: false
  },
  {
    id: "exam-type",
    title: "Exam Type",
    required: false,
    type: inputMappings.dropDown,
    subtype: dropdownSubtypes.examType
  }
];