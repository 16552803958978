import { getFormByType } from "../Forms/paths"
import { graduateFacultyOptions, loggedinUserData } from "../util/enums"
import * as Queries from "../graphql/queries";
import * as Mutations from "../graphql/mutations";
import { inputMappings } from "../util/enums";
import { personInfo } from "../util/constants";
import { createSkType } from "../Forms/transform";

const mailAddress =  `{
    address1
    address2
    city
    country
    name
    postalCode
    state
}`

export const createFormTypeBasedFormRequest = (formType = "", query = true, options = {}) => {
    let form = ''
    if(query) {
        form += `
        query GetForm($formType: String!, $formId: String!, $studentid: String) {`
    } else {
        form += `
        mutation UpdateForm($input: FormInput) {`
    }
    form += _createFormTypeBasedFormRequest(formType, query, options)
    form += `}`
    return form
}

export const _createFormTypeBasedFormRequest = (formType = "", query = true, options = {}) => {
    const { sections } = getFormByType(formType) || {}
    if(!sections?.length) {
        return query ? Queries.getForm : Mutations.updateForm
    }
    let baseFields = options?.baseFields || `
        id
        studentid
        academicPlan
        careerNumber
        pcdIndicator
        requesterId
        createdBy
        name
        createdDate
        formType
        status
        action
    `
    let form = ''
    if(query) {
        form += `
            getForm(formType: $formType, formId: $formId, studentid: $studentid) {`
    } else {
        form += `
            updateForm(input: $input) {`
    }

    form += `
            form {
                ${baseFields}`
    let customSplits = {}
    let skTypeFields  = {}
    sections.forEach(({ questions, id }) => {
        if(questions?.length) {
            for(let question of questions) {
                const { dataField: sdf, choices, type, subtype, questions: innerQs, graphql, results, ...rest } = question
                const  { skType, dataField: dataFieldFirstPart } = createSkType(sdf)
                let sectionDf = dataFieldFirstPart
                const splitOnPeriod = sdf?.includes('.') && sdf.split('.')
                if(splitOnPeriod?.[1]) {
                    const parent = splitOnPeriod[0]
                    if(!customSplits?.[parent]) {
                        customSplits[parent] = {}
                    }
                    if(!customSplits?.[parent]?.subfields) {
                        customSplits[parent].subfields = []
                    }
                    if(!customSplits?.[parent]?.field) {
                        customSplits[parent].field = parent
                    }
                    customSplits[parent].subfields.push({
                        field: splitOnPeriod[1],
                        subtype,
                        questions: innerQs
                    })
                }
                
                if (type === loggedinUserData.mailingAddress) {
                    form += `
                       ${sdf} ${mailAddress}
                    `
                } else if (!splitOnPeriod?.[1] && sectionDf && innerQs?.length) {
                    if(skType) {
                        if(!skTypeFields[sectionDf]) skTypeFields[sectionDf] = { fields: []}
                        innerQs.forEach(questionObject => {
                            if(questionObject.dataField) {
                                skTypeFields[sectionDf].fields.push(questionObject.dataField)
                            }
                        })
                        skTypeFields[sectionDf].fields.push("skType");
                    } else {
                        form += _addGroup(sectionDf, innerQs, false, {
                            query
                        })
                    }
                } else if(!splitOnPeriod?.[1] && sectionDf && graphql?.length) {
                    form += _addGroup(sectionDf, graphql, false, {
                        query
                    })
                } else if (!splitOnPeriod?.[1] && !sectionDf && innerQs?.length) {
                    for(const innerQ of innerQs) {
                        if(innerQ.dataField) {
                            form += `
                                ${innerQ.dataField}`
                        }
                    }
                } else if(type === inputMappings.datetime && ([rest.startTime, rest.endTime].includes(true))) {
                    form += `
                        ${sectionDf} {
                            date
                            start
                            end
                        }
                    `;
                } else if(!splitOnPeriod?.[1] && graduateFacultyOptions.includes(subtype)) {
                    form += `
                        ${subtype} {
                            ${personInput}
                        }
                    `
                } else if (!splitOnPeriod?.[1] && sectionDf) {
                    form += `
                        ${sectionDf}`
                }
                    

                if(!splitOnPeriod?.[1] && choices?.length) {
                    let addedSingleLevelFields = []
                    for(let choice of choices)  {
                        const { dataField: choiceDf, questions: choiceQs, ...rest } = choice
                        if (rest?.type === loggedinUserData.mailingAddress) {
                            form += `
                               ${choiceDf} ${mailAddress}
                            `
                        } else if (choiceDf && choiceQs?.length) {
                            form += _addGroup(choiceDf, choiceQs, false, {
                                query
                            })
                        } else if (choiceDf) {
                            if(!addedSingleLevelFields.includes(choiceDf)) {
                                addedSingleLevelFields.push(choiceDf)
                            }
                        }

                    }
                    for(let newField of addedSingleLevelFields) {
                        if(newField) {
                            form += `
                                ${newField}`
                        }

                    }
                }

                if(results?.length) {
                    for(let result of results) {
                        if(result.dataField) {
                            form += `
                            ${result.dataField}`
                        }
                    }
                }
            }
        }
    })
    if(Object.keys(customSplits).length) {
        Object.values(customSplits).forEach(({ field, subfields }) => {
            if(subfields?.length) {
                const grouped =  _addGroup(field, subfields, false, {
                    query
                })
                form += grouped
            } else {
                form += field
            }
        } )
    }

    for(let entry of Object.entries(skTypeFields)) {
        const group = entry[0]
        const fields = [...new Set(entry[1]?.fields || [])]
        form += `
            ${group} {
        `
        let spacer = `
        `
        form += fields.join(spacer)
        form += `
        }`
    }

    form += `
}
    status
}
`

return form
}

const personRead = Object.keys(personInfo.read).map(field => `
    ${field}
`)

const personInput = Object.keys(personInfo.write).map(field => `
    ${field}
`)

function _addGroup(section, questions, ignore, options = {}) {
    let form = ''
    let personFields = options?.query ? personRead : personInput
    form += `
        ${section} {
    `
    if (Array.isArray(questions) ) {
        questions.forEach(( { field, type, subtype, dataField, questions: nestedQuestions } ) => {
            let f = dataField || field

             if (graduateFacultyOptions.includes(subtype) || type === inputMappings.nameNLookup) {    
                if(ignore) {
                    form += personFields
                } else {
                    form += `
                        ${subtype} {
                            ${personFields}
                        }
                    `    
                }
            } else if (f && graduateFacultyOptions.includes(f)) {    
                form += `
                    ${f} {
                        ${personFields}
                    }
                `    
            } else if(f && nestedQuestions?.length) {    
                form += _addGroup(f, nestedQuestions, true, options)
            } else if (f) {
                form += `
                ${f}`
            }    
        })
    }
    form += `
        }
    `
    return form
}

export const getDashboard = `
  query GetDashboard($filter: FormsFilter!) {
    getForms(filter: $filter) {
      forms {
        id
        studentid
        completedDate
        createdBy
        createdDate
        formType
        lastUpdatedDate
        status
        substatus
        submittedDate
        action
        termCode
      }
    }
    getStudentAlerts {
        alerts {
          title
          subtitle
          description
        }
      }
  }
`;

const _getStudentInfo = `
    getStudentInfo(studentid: $studentid) {
        lastModified
        record {
            acadPlan
            address {
            address1
            address2
            city
            country
            name
            postalCode
            state
            }
            admitTerm
            cumulativeGPA
            cumulativeHours
            degree
            earnedHours
            email
            feesPaid
            missingGrades
            withdrawn
            phone
            postCandidacy
            osuid
            name
            asOfTerm
        }
        status
    }
`

export const getStudentInfo = `
    query GetStudentInfo($studentid: String) {
        ${_getStudentInfo}    
    }  
`
export const getPerson = `
  query GetPerson($emplid: String!) {
    getPerson(emplid: $emplid) {
      emplid
      nameN
      name
      firstName
      lastName
      middleName
      facultyEmail
      facultyNomination
      phone
      address1
      program
      category
    }
  }
`;

const _getFormHistory = `
    getFormHistory(emplid: $emplid, formType: $formType, formId: $formId) {
        history {
            step
            status
            items {
                action
                date
                description
                emplid
                name
                task
                taskActions
            }
        }
        lastModified
        status
    }
`

export const getFormHistory = `
  query GetFormHistory($emplid: String!, $formType: String!, $formId: String!) {
    ${_getFormHistory}
  }
`;

export const createTabbedDataQuery =  (formType = "", options = {}) => {
    return `
        query getTabbedData($studentid: String!, $emplid: String!, $formId: String!, $formType: String!) {
        ${_getStudentInfo}
        ${_getFormHistory}
        ${_createFormTypeBasedFormRequest(formType, true, options)}
      }
    `
}

export const getExternalUserInfo = `
    query GetExternalUserInfo {
        getExternalUserInfo {
            externalUserId
            emailAddr
            firstName
            lastName
            createdDt
            lastUpdatedDt
            roleName
        }
  }
`;