import { generateClient } from "aws-amplify/api";
import { sortBy } from "lodash";
import { ACADEMIC_PROGRAMS, DROPDOWNS, GRADUATE_CONTACT_SEARCH, FORMS_SEARCH, SEARCH_PROGRAM_MAP, GRAD_RECORDS_SEARCH, TERMS, FACULTY_SEARCH } from "../actions-index.js";
import * as Queries from '../graphql/queries';
import { ACTION_STATUS, ACTIVE, DEGREE } from "../util/constants";
import { dropdownSubtypes } from "../util/enums.js";
import { buildAction } from "../util/functions";
import { updateFormDetailFields } from "../Form/actions";
import { transformSearchForms, transformSearchGradRecs } from "./transform";

const API = generateClient();

export function getAcademicPrograms() {
    return async (dispatch) => {
        try {
            let commonComponentArray = []
            dispatch(buildAction(ACADEMIC_PROGRAMS, { status: ACTION_STATUS.LOADING }));
            dispatch(buildAction(DROPDOWNS, { 
                type: dropdownSubtypes.graduatePrograms, 
                status: ACTION_STATUS.LOADING
            }));
            const response = await API.graphql({ query: Queries.getAcademicPlans, variables: { filter: { status: "A" } } });
            const academicPlans = (response?.data?.getAcademicPlans?.academicPlans ?? []);
            if(!response || !academicPlans || !academicPlans?.length) {
                return dispatch(buildAction(ACADEMIC_PROGRAMS, { 
                    status: ACTION_STATUS.SUCCESS, 
                    data: [],
                }));
            }
            let academicPrograms = new Set();

            let index = 0
            for(const academicPlan of academicPlans) {
                if(academicPlan.graduateProgram) {
                    academicPrograms.add(academicPlan.graduateProgram);
                    let newPlan = {
                        id: academicPlan.academicPlan + academicPlan.graduateProgram + index,
                        title: academicPlan.academicPlan,
                        value: academicPlan.academicPlan,
                        gradLevel: academicPlan?.gradLevel,
                        degree: academicPlan?.degree,
                        academicPlan: academicPlan.graduateProgram // used to filter faculty dropdowns by selected grad plan (faculty use program) 
                    }
                    if(academicPlan.acadProgDescr) {
                        newPlan.title += ` - ${academicPlan.descr}`
                    }
                    commonComponentArray.push(newPlan)
                }
                index++    
            }
            academicPrograms = Array.from(academicPrograms);
            academicPrograms.sort();
            dispatch(buildAction(ACADEMIC_PROGRAMS, { 
                status: ACTION_STATUS.SUCCESS, 
                data: academicPrograms
            }));

            dispatch(buildAction(DROPDOWNS, { 
                type: dropdownSubtypes.graduatePrograms, 
                data: sortBy(commonComponentArray, ["title"])
            }));
        } catch(error) {
            console.error("Get Academic Programs Error: ", error);
            dispatch(buildAction(ACADEMIC_PROGRAMS,{ status: ACTION_STATUS.ERROR }));
            dispatch(buildAction(DROPDOWNS, { 
                type: dropdownSubtypes.graduatePrograms, 
                status: ACTION_STATUS.ERROR
            }));
        }
    };
}

export function getSearchProgramMap() {
    return async (dispatch) => {
        try {
            dispatch(buildAction(SEARCH_PROGRAM_MAP, { status: ACTION_STATUS.LOADING }));
            const response = await API.graphql({ query: Queries.getAcademicPlans, variables: { filter: { status: "A" } } });
            const academicPlans = (response?.data?.getAcademicPlans?.academicPlans ?? []);
            const graduateProgramMap = {};
            for(const academicPlan of academicPlans) {
                if(academicPlan.degree !== DEGREE.SPECIALIZATION) {
                    if(!graduateProgramMap[academicPlan.graduateProgram]) {
                        graduateProgramMap[academicPlan.graduateProgram] = { acadProgDescr: "", academicPlans: [] };
                    }
                    if(academicPlan.acadProgDescr) graduateProgramMap[academicPlan.graduateProgram].acadProgDescr = academicPlan.acadProgDescr;
                    graduateProgramMap[academicPlan.graduateProgram].academicPlans.push({ academicPlan: academicPlan.academicPlan, descr: academicPlan.descr, status: academicPlan.status });
                }
            }
            dispatch(buildAction(SEARCH_PROGRAM_MAP, { status: ACTION_STATUS.SUCCESS, data: graduateProgramMap }));
        } catch(error) {
            console.error("Get Graduate Program Map Error: ", error);
            dispatch(buildAction(SEARCH_PROGRAM_MAP,{ status: ACTION_STATUS.ERROR }));
        }
    };
}

export function getGradRecTerms(options) {
    const { career = "GRAD", showFromPresent, showHistorical} = options
    let variables = { career }
    if(showFromPresent) {
        variables.future = 'Y'
    }
    if(showHistorical) {
        variables.history = 'Y'
    }

    return async (dispatch) => {
        try {
            dispatch(buildAction(TERMS, { status: ACTION_STATUS.LOADING }));
            const response = await API.graphql({ query: Queries.getTerms, variables });
            const terms = response?.data?.getTerms?.terms
            if(!terms.length) {
              throw new Error('unable to get terms')
            }

            dispatch(buildAction(TERMS, { status: ACTION_STATUS.SUCCESS, data: terms }));
        } catch(error) {
            console.error("Get Terms Error: ", error);
            dispatch(buildAction(TERMS,{ status: ACTION_STATUS.ERROR }));
        }
    };
}


export function searchGraduateContacts(academicPlan, affiliation, query) {
    return async (dispatch) => {
        try {
            dispatch(buildAction(GRADUATE_CONTACT_SEARCH, { status: ACTION_STATUS.LOADING }));
            const response = await API.graphql({ query: Queries.getGraduateContacts, variables: { filter: { academicPlan, active: ACTIVE.YES, affiliation, query } } });
            const graduateContacts = (response?.data?.getGraduateContacts?.graduateContacts ?? []);
            dispatch(buildAction(GRADUATE_CONTACT_SEARCH, { status: ACTION_STATUS.SUCCESS, data: graduateContacts }));
        } catch(error) {
            console.error("Search Graduate Contacts Error: ", error);
            dispatch(buildAction(GRADUATE_CONTACT_SEARCH,{ status: ACTION_STATUS.ERROR }));
        }
    };
}

export function resetGraduateContactSearch() {
    return (dispatch) => {
        dispatch(buildAction(GRADUATE_CONTACT_SEARCH, { status: "" }));
    };
}

export function searchFaculty(filter = {active: null, program: null, query: ""}) {
    return async (dispatch) => {
        console.log('searchFaculty filter', filter)
        const { active, program, query } = filter;
        try {
            dispatch(buildAction(FACULTY_SEARCH, { status: ACTION_STATUS.LOADING }));
            const response = await API.graphql({ query: Queries.searchGraduateFaculty, variables: { active, program, query } });
            console.log("response from search faculty", response);
            const graduateFaculty = (response?.data?.searchGraduateFaculty?.graduateFaculty ?? []);
            dispatch(buildAction(FACULTY_SEARCH, { status: ACTION_STATUS.SUCCESS, data: graduateFaculty }));
        } catch(error) {
            console.error("Search Graduate Faculty Error: ", error);
            dispatch(buildAction(FACULTY_SEARCH,{ status: ACTION_STATUS.ERROR }));
        }
    };
}

export function searchForms(filter) {
    // console.log("****** searchForms action filter has ", filter);
    // const { studentid } = filter;
    // console.log("studentid ", studentid);
    console.log("searchForms filter ", filter);

    return async (dispatch) => {
        try {
            dispatch(buildAction(FORMS_SEARCH, { status: ACTION_STATUS.LOADING }));
            // const response = await API.graphql({ query: Queries.getForms, variables: { filter: studentid } });
            const response = await API.graphql({ query: Queries.searchForms, variables: { filter: filter } });
            console.log("****** searchForms actions: response contains", response);
            const forms = (response?.data?.searchForms?.forms ?? []);
            console.log("****** searchForms actions: forms contains", forms);
            await dispatch(updateFormDetailFields(forms));
            // dispatch(buildAction(LANDING_FORMS, { status: ACTION_STATUS.SUCCESS, data: transformLandingForms(forms) }));
            // Need to do transform here
            // dispatch(buildAction(FORMS_SEARCH, { status: ACTION_STATUS.SUCCESS, data: forms }));
            dispatch(buildAction(FORMS_SEARCH, { status: ACTION_STATUS.SUCCESS, data: transformSearchForms(forms) }));
        } catch(error) {
            console.error("Search Forms Error: ", error);
            dispatch(buildAction(FORMS_SEARCH,{ status: ACTION_STATUS.ERROR }));
        }
    };
}

export function resetFormsSearch() {
    return (dispatch) => {
        dispatch(buildAction(FORMS_SEARCH, { status: "" }));
    };
}

export function searchGraduationRecords(emplid, partialSk, filter) {
    // console.log("****** searchGraduationRecords action filter has ", filter);
    console.log("****** searchGraduationRecords action emplid has ", emplid, " partialSk has ", partialSk, " filter has ", filter);
    return async (dispatch) => {
        try {
            // const emplid = filter.studentid;
            dispatch(buildAction(GRAD_RECORDS_SEARCH, { status: ACTION_STATUS.LOADING }));
            let options = {}; // optioins to marshall pk and partialSk or not
            const response = await API.graphql({ query: Queries.getGraduationRecords, variables: { emplid, partialSk, filter } });
            console.log("****** searchGraduationRecords actions: response contains", response);
            const graduationRecords = (response?.data?.getGraduationRecords?.graduationRecords ?? []);
            console.log("************ searchGraduationRecords actions: graduationRecords contains", graduationRecords);
            dispatch(buildAction(GRAD_RECORDS_SEARCH, { status: ACTION_STATUS.SUCCESS, data: transformSearchGradRecs(graduationRecords, filter) }));
            // dispatch(buildAction(GRAD_RECORDS_SEARCH, { status: ACTION_STATUS.SUCCESS, data: graduationRecords }));
        } catch(error) {
            console.error("Search Forms Error: ", error);
            dispatch(buildAction(GRAD_RECORDS_SEARCH,{ status: ACTION_STATUS.ERROR }));
        }
    };
}

export function resetGraduationRecordsSearch() {
    return (dispatch) => {
        dispatch(buildAction(GRAD_RECORDS_SEARCH, { status: "" }));
    };
}