import { add, differenceInMinutes, format, isAfter, isBefore, isValid, isWeekend, subHours } from "date-fns";
import { DATE_FORMAT, TIME_FORMAT } from "../../../../util/constants";
import { inputMappings } from "../../../../util/enums";

export const DATE_ID = "date";
export const END_TIME_ID = "end";
export const START_TIME_ID = "start";

export const createDateTimeQuestions = (dateId, startId, endId) => {
  let questions = [
    {
      id: dateId,
      type: inputMappings.date,
      title: "Date",
    },
  ];
  if (!startId && !endId) {
    questions.push({
      id: `${dateId}-time`,
      type: inputMappings.time,
      title: "Time",
    });
  }
  if (startId) {
    questions.push({
      id: startId,
      type: inputMappings.time,
      title: "Start Time",
    });
  }
  if (endId) {
    questions.push({
      id: endId,
      type: inputMappings.time,
      title: "End Time",
    });
  }
  return questions;
};

export const formatDate = (currentValue) => {
  let value = null;
  if (isValid(currentValue)) {
    value = currentValue;
  } else if (currentValue) {
    const parsedDate = new Date(currentValue);
    if (isValid(parsedDate)) {
      value = parsedDate;
    }
  }
  return value
}

export const alterTimeToMatchDate = (existingDate, newValue) => {
  let value = newValue;
  let month, day, year;
  if (isValid(existingDate)) {
    day = existingDate.getDate();
    month = existingDate.getMonth();
    year = existingDate.getFullYear();
    if (isValid(value)) {
      value.setDate(day);
      value.setMonth(month);
      value.setFullYear(year);
    }
  }
  return value
};

export const mergeDateWithTime = (date, time) => {
  if (!time) {
    return null;
  }
  let formattedDate = new Date(date);
  formattedDate = isValid(formattedDate) ? formattedDate : null;
  formattedDate = formattedDate && format(formattedDate, DATE_FORMAT);
  if (!formattedDate) {
    return null;
  }
  formattedDate += ` ${time}`;
  return new Date(formattedDate)
};

export const createValidationFields = (
  stateValues,
  validation,
  dateId,
  startId,
  endId
) => {
  let values = {
    errors: [],
    [dateId]: {
      errors: []
    },
    [startId]: {
      errors: []
    },
    [endId]: {
      errors: []
    }
  }
  const date = stateValues?.[dateId] && new Date(stateValues[dateId])
  const startTime = stateValues?.[startId] && new Date(stateValues[startId])
  const endTime = stateValues?.[endId] && new Date(stateValues[endId])
  const formattedStartTime = isValid(startTime) && format(startTime, TIME_FORMAT)
  
  const deadlineDate = validation?.days && add(new Date(), { days: validation.days });
  if(isValid(date) && isValid(deadlineDate) && isBefore(date, deadlineDate)) {
    values[dateId].errors.push(`Please select a date within ${validation.days} days.`)
  }

  values.minDate = mergeDateWithTime(date, validation?.begin);
  values.maxDate = mergeDateWithTime(date, validation?.end);
  if(isValid(date) && isValid(startTime) && isBefore(startTime, values.minDate)) {
    values[startId].errors.push(`Please select a time after ${format(values.minDate, TIME_FORMAT)}`)
  }
  if(endTime) {
    if(isValid(date) && isValid(endTime) && isAfter(endTime, values.maxDate)) {
      values[endId].errors.push(`Please select a time before ${format(values.maxDate, TIME_FORMAT)}`)
    }
  } else {
    if(isValid(date) && isValid(startTime) && isAfter(startTime, values.maxDate)) {
      values[startId].errors.push(`Please select a time before ${format(values.maxDate, TIME_FORMAT)}`)
    }
  }
  if(isAfter(startTime, endTime)) {
    values[endId].errors.push(`Please select a time after ${formattedStartTime}`)
  }

  if(validation?.weekend && isValid(date)) {
    const weekend = isWeekend(date)
    if(weekend) {
      values[dateId].errors.push(`Please select a university business day.`)
    }
  }

  if(validation?.durationInHours && isValid(date) && isValid(endTime) && isValid(startTime)) {
    const differenceBetween = differenceInMinutes(endTime, startTime)
    const convertedHoursToMin = (validation.durationInHours * 60)
    const subtractEarliestStartTime = subHours(values.maxDate, validation.durationInHours)

    if (isAfter(startTime, subtractEarliestStartTime)) {
      values[startId].errors.push(`Event should start by ${format(subtractEarliestStartTime,TIME_FORMAT )}, so it can be at least 2 hours and end before ${format(values.maxDate, TIME_FORMAT)}`)
    }
    if(differenceBetween < convertedHoursToMin) {
      values[endId].errors.push(`Please select a time two hours after ${formattedStartTime}`)
    }
  }

  values.errors = values.errors.concat(values[dateId].errors, values[startId].errors, values[endId].errors);

  return values
};