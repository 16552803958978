import { Mui } from "@osu/react-ui";
import { Fragment } from "react";
import { WITHDRAWAL_REASON } from "../../util/constants";
import { dropdownSubtypes, inputMappings } from "../../util/enums";

export const formSections = [
  {
    id: "form-information",
    questions: [
      {
        title: "Graduation Level",
        id: "graduation-information-level",
        required: true,
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.graduationLevel, 
        dataField: "gradLevel",
        branch: "graduation-information-plan"
      },
      {
        title: "Graduation Plan",
        id: "graduation-information-plan",
        required: true,
        type: inputMappings.dropDown,
        dataField: "academicPlan",
        subtype: dropdownSubtypes.graduatePrograms,
        filter: [
          {
            field:  "graduation-information-level",
            match: "gradLevel",
            operation: "includes"
          }
        ],
        branch: "advisor-info",
        dependencyHelperText: "Please select a level to select the appropriate plan.",
        dependencies: ["graduation-information-level"]
      },
      {
        id: "advisor-info",
        type: inputMappings.dropDown,
        title: "Advisor",
        subtype: dropdownSubtypes.advisor,
        dependencies: ["graduation-information-plan"],
        dataField: "advisor",
        dependencyHelperText: "Please select a plan to select the appropriate advisor.",
        required: true
      },
      {
        id: "reason-for-withdrawal",
        type: inputMappings.radio,
        title: "Reason for Withdrawal",
        required: true,
        dataField: "reason",
        choices: [
          {
              id: "medical",
              title: "Medical",
              value: WITHDRAWAL_REASON.MEDICAL
          },
          {
              id: "family",
              title: "Family",
              value: WITHDRAWAL_REASON.FAMILY
          },
          {
              id: "financial",
              title: "Financial",
              value: WITHDRAWAL_REASON.FINANCIAL
          },
          {
              id: "professional-opportunity",
              title: "Professional Opportunity",
              value: WITHDRAWAL_REASON.PROFESSIONAL_OPPORTUNITY
          },
          {
              id: "military",
              title: "Military",
              value: WITHDRAWAL_REASON.MILITARY
          },
          {
              id: "other",
              title: "Other",
              value: WITHDRAWAL_REASON.OTHER,
              branch: "other-reason-for-withdrawal"
          }
        ]
      },
      {
        id: "other-reason-for-withdrawal",
        type: inputMappings.textInput,
        title: "Other Reason for Withdrawal",
        dataField: "otherReason",
        dependencies: ["reason-for-withdrawal"],
        required: true,
        inputProps: {
          maxLength: 150
        }
      }
    ]
  },
  {
    id: "withdrawal-summary",
    Component: () => {
      return <Fragment>
        <Mui.Typography className="margin-bottom-2">
          If you are a pre-candidacy doctoral, master’s, or a professional doctorate student, please note that you will be evaluated for readmission into the program 
          by the graduate studies committee should you choose to return.
        </Mui.Typography>
        <Mui.Typography>
          If you are a post-candidacy doctoral student, a withdrawal from your PhD program means that your candidacy will be cancelled, and a registration lock will 
          be placed on your student account.
        </Mui.Typography>
      </Fragment>
    }
  }
];