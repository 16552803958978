import React, { Fragment, useEffect, useState } from "react";
import { forOwn } from "lodash";
import { format, isValid, parseISO } from "date-fns";
import { inputMappings } from "../../../../util/enums";
import { Mui } from "@osu/react-ui";
import DatePicker from "./DatePicker";
import TimePicker from "./TimePicker";
import { alterTimeToMatchDate, createDateTimeQuestions, createValidationFields, DATE_ID, END_TIME_ID, formatDate, START_TIME_ID } from "./util";
import { TIME_FORMAT } from "../../../../util/constants";


function DateTimeGroup(props = {}) {
  const {
    id,
    value,
    type,
    onChange,
    className,
    placeholder,
    startTime,
    endTime,
    validation,
    helperText: summary,
    error,
    required = false,
    ...rest
  } = props;
  const currentValue = Object.prototype.hasOwnProperty.call(value || {}, 'value') ? value.value : value
  let dateId = DATE_ID;
  let startId = startTime && START_TIME_ID;
  let endId = endTime && END_TIME_ID;
  let helperText = summary
  const [groupDateValues, setGroupDateValues] = useState({
    [dateId]: null,
    [startId]: null,
    [endId]: null,
  })
  const syncDateValues = JSON.stringify(currentValue || {}) === JSON.stringify(groupDateValues || {})
  useEffect(() => {
    const keysOfCurrentState = Object.keys(currentValue || {})
    const allowStateUpdate = (keysOfCurrentState || []).filter(key => {
      return [dateId, startId, endId].includes(key)
    }).length
    if(!syncDateValues && allowStateUpdate) {
      setGroupDateValues(currentValue)
    }
  }, [currentValue, dateId, endId, startId, syncDateValues])

  const questionsToRender = createDateTimeQuestions(dateId, startId, endId);
  const dateOfDay = (groupDateValues?.[dateId] ? new Date(groupDateValues?.[dateId]) : null);

  const { minDate, maxDate, ...remaining } = createValidationFields(
    groupDateValues,
    validation,
    dateId,
    startId,
    endId
  );

  return (
    <Fragment>
      <Mui.Box display="flex">
        {questionsToRender.map(({ type, id: currentQId, title }, idx) => {
          let cls = className || "";
          const onGroupChange = (val) => {
            let value = val;
            if(isValid(value)) {
              if(currentQId !== dateId) value = alterTimeToMatchDate(dateOfDay, val)
              value.setSeconds(0);
              value.setMilliseconds(0);
              value = value.toISOString() 
            }
            setGroupDateValues((existingState = {}) => {
              const newValues = {
                ...existingState,
                [currentQId]: value
              }
              if(currentQId === dateId) {
                forOwn(newValues, (newValue, newKey) => {
                  if(newKey !== dateId) {
                    newValues[newKey] = ((isValid(val) && newValue) ? alterTimeToMatchDate(val, parseISO(newValue)).toISOString() : newValue);
                  }
                });
              }
              onChange(newValues)
              return newValues
            });
          };
          if (helperText) {
            cls = undefined;
          }
          if (questionsToRender.length - 1 !== idx) {
            cls += " margin-right-2";
          }
          const preparedValue = formatDate(groupDateValues?.[currentQId])
          const errors = remaining?.[currentQId]?.errors || []
          const fieldId = `${id}-${currentQId}`;
          const fieldKey = `${fieldId}${idx}`;
          const hasError = ((!!error && !preparedValue) || errors.length > 0);
          const errorMessage = ((!!error && !preparedValue) ? error : (errors.length > 0 ? errors[0] : ""));
          if (type === inputMappings.time) {
            const formattedTime = (isValid(minDate) ? format(minDate,TIME_FORMAT) : null);
            const { dataField, ...timePickerProps} = rest;
            return (
              <TimePicker
                slotProps={{
                  textField: {
                    error: hasError,
                    helperText: errorMessage,
                    required
                  }
                }}
                placeholder={formattedTime}
                {...timePickerProps}
                id={fieldId}
                key={fieldKey}
                label={title}
                className={cls}
                initialFocusedDate={formattedTime}
                disableIgnoringDatePartForTimeValidation={true}
                minTime={minDate}
                maxTime={maxDate}
                onChange={onGroupChange}
                value={preparedValue}
              />
            );
          }
          return (
            <DatePicker
              {...rest}
              slotProps={{
                textField: {
                  error: hasError,
                  helperText: errorMessage,
                  required
                }
              }}
              initialFocusedDate={new Date()}
              id={fieldId}
              key={fieldKey}
              onChange={onGroupChange}
              label={title}
              className={cls}
              value={preparedValue}
            />
          );
        })}
      </Mui.Box>
      {helperText && (
        <Mui.FormHelperText className={className}>
          {helperText}
        </Mui.FormHelperText>
      )}
    </Fragment>
  );
}

DateTimeGroup.defaultProps = {
  value: null,
};

export default DateTimeGroup;
