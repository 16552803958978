import { Mui, MuiStyles } from "@osu/react-ui";
import React, { Fragment } from "react";
import InputMapper from "./Mapper";

const RadioInput = (props = {}) => {
  const {
    id,
    required,
    error,
    label,
    value,
    onChange,
    choices,
    description,
    sectionState,
    dataField,
    ...rest
  } = props;
  
  const labelId = `${id}-label`;
  const descriptionId = `${id}-${id}-label-description`;
  const radioId = `${id}-radio`;

  const theme = MuiStyles.useTheme();

  return (
    <Mui.FormControl required={required} error={error} {...rest}>
      <Mui.FormGroup id={id}>
        <Mui.FormLabel sx={{ "&": { color: theme.palette.text.primary } }} id={labelId}>
          {label}
        </Mui.FormLabel>
        {!!error && <Mui.FormHelperText id={`${descriptionId}-error`}>{error}</Mui.FormHelperText>}
        {description && <Mui.Typography id={descriptionId} color="secondary" variant="body2">{description}</Mui.Typography>}
        <Mui.RadioGroup
          aria-labelledby={labelId}
          aria-describedby={`${descriptionId} ${
            error ? `${descriptionId}-error` : ""
          }`.trim()}
          value={value?.value || value}
          onChange={(event, newValue) => {
            const matchingOption = choices.find(opt => opt?.value === newValue)
            const val = matchingOption || newValue
            const value = !val && event?.id ? event : val
            onChange({
              id,
              value
            }) 
          }}
          id={radioId}
        >
          {
            choices.map((choice, index) => {
              const dependencyOn = choice?.dependencies?.[0]

              const match = choices.find(matchingChoice => matchingChoice.branch === choice?.id)
              let currentVal = value?.value || value

              let showInputGroup = dependencyOn 
                && (dependencyOn === id) 
                && (match?.value === currentVal)

              if(dependencyOn) {
                if(showInputGroup) {
                  const relevantValue = sectionState?.[choice.id]
                  const lastRadio = choices.length - 1 === index
                  const error = (typeof relevantValue !== "undefined" ? relevantValue.error : choice.error);
                  return <InputMapper 
                    {...choice}
                    key={`${radioId}-${choice.id}-${index}`}
                    label={choice.title}
                    summary={choice.summary}
                    onChange={(values) => {
                      const { dataField, ...rest } = values
                      onChange({
                        nestedId: choice.id,
                        nested: true,
                        dataField: choice.dataField,
                        value: rest
                      })
                    }}
                    value={relevantValue?.value || relevantValue}
                    error={error}
                    id={choice.id}
                    className={`margin-left-4${lastRadio ? "" : " margin-bottom-2"}`}
                  />
                }
                return null
              }

              return (
                <Fragment key={`${radioId}-${choice.value}-${index}`}>
                  <RadioLabel
                    className={choices.length - 1 !== index ? "margin-right-3" : ""}
                    inputProps={{ required: required, name: `${id}-${choice.value}` }}
                    value={choice.value}
                    label={choice.title}
                  />
                 {choice?.summary && 
                    (React.isValidElement(choice.summary) 
                      ? choice.summary 
                      :  <Mui.FormHelperText className="margin-left-4">
                            {choice?.summary}
                        </Mui.FormHelperText>
                    )
                  }
                </Fragment>
              );
            })
          }
        </Mui.RadioGroup>
      </Mui.FormGroup>
    </Mui.FormControl>
  );
};

const RadioLabel = (props = {}) => {
    const { inputProps, ...rest } = props

    return <Mui.FormControlLabel 
        {...rest}
        control={<Mui.Radio inputProps={inputProps} color="primary" />} 
    />
}

RadioInput.defaultProps = {
  value: ''
}

export default RadioInput;
